import { faBell, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Backhost from "../../required";
import { motion } from "framer-motion";
import React, { useState, useContext } from "react";

import authContext from "../../context/auth/authContext";
import axios from "axios";
const Notification = (props) => {
  const {
    TotalNotification,
    setTotalNotification
  } = useContext(authContext);
  const [Open, setOpen] = useState(false);
  const [Notification, setNotification] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Success, setSucess] = useState(false);

  
  const NotificationClick = async () => {
    await setLoading(true);
    let headersList = {
      Accept: "*/*",
      "auth-token": `${props.token}`,
    };
    let reqOptions = {
      url: `${Backhost.Backhost}/api/auth/user/notification`,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    response = response.data;
    if (response.sucess) {
      setSucess(true);
      setNotification(response.notification);
      setLoading(false);
    }
    
    await axios.request({
      url: `${Backhost.Backhost}/api/auth/user/notification`,
      method: "PUT",
      headers: headersList,
    })

    await setLoading(false);
  };

  const toggleOpen = () => {
    if (Open) {
      setOpen(false);
      setTotalNotification(0)
    } else {
      setOpen(true);
      NotificationClick();
    }
  };
  function TimeSince(data) {
    var date = new Date(data.date);
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return (
        Math.floor(interval) + ` year${Math.floor(interval) > 1 && "s"} ago`
      );
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return (
        Math.floor(interval) +
        ` month${Math.floor(interval) > 1 ? "s" : ""} ago`
      );
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return (
        Math.floor(interval) + ` day${Math.floor(interval) > 1 ? "s" : ""} ago`
      );
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return (
        Math.floor(interval) + ` hour${Math.floor(interval) > 1 ? "s" : ""} ago`
      );
    }
    interval = seconds / 60;
    if (interval > 1) {
      return (
        Math.floor(interval) +
        ` minute${Math.floor(interval) > 1 ? "s" : ""} ago`
      );
    }
    return Math.floor(seconds) + ` seconds ago`;
  }

  return (
    <div>
      <Tooltip title="Notification">
        <IconButton onClick={toggleOpen}>
          <Badge badgeContent={TotalNotification} color="secondary">
            <FontAwesomeIcon icon={faBell} />
          </Badge>
        </IconButton>
      </Tooltip>
      {Open && (
        <div
          onClick={toggleOpen}
          className="fixed right-0 top-0 h-screen w-screen bg-transparent block "
        >
          <div className="fixed top-14 bg-white right-2 w-80 h-96 shadow-2xl p-1 overflow-scroll">
            {Loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <motion.div
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                {Success ? (
                  <div>
                    {Notification.map((data) => {
                      return (
                        <Link to={data.redirect}>
                        <div
                          className={`${
                            data.unread && "bg-blue-50 "
                          } p-1 m-1 shadow  cursor-pointer hover:shadow-lg transition duration-300`}
                        >
                          <div>
                            <FontAwesomeIcon icon={faBell} /> {data.message}
                          </div>
                          <span className="ml-1 text-sm">
                            <FontAwesomeIcon icon={faClock} />{" "}
                            <TimeSince date={data.date} />
                          </span>
                        </div>
                        </Link>
                      );
                    })}
                  </div>
                ) : (
                  <p className="text-center m-2"> No Notification Found</p>
                )}
              </motion.div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
