import React, { useState, useEffect } from "react";
import Backhost from "../../required";
const Category = () => {
  const [CategoryList, setCategoryList] = useState([]);
  const [Sucess, setSucess] = useState(false);

  const getCategory = async () => {
    let headersList = {
      Accept: "*/*",
    };

    let data = await fetch(`${Backhost.Backhost}/api/items/category`, {
      method: "GET",
      headers: headersList,
    });
    data = await data.json();
    await setSucess(data.sucess);
    await setCategoryList(data.categories);
  };
  useEffect(() => {
    getCategory();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      {Sucess ? (
        <>
          {CategoryList.map((data) => {
            return <option key={data._id} value={data.value}>{data.value}</option>;
          })}
        </>
      ) : (
        <option disabled value={"Loading"}>
          Loading
        </option>
      )}
    </>
  );
};

export default Category;
