import { CircularProgress, Rating } from "@mui/material";
import React, { useState, useContext } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./tags/new.svg";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import Tag from "./tags/Tag";
import currencySymb from "../../required";
import authContext from "../../context/auth/authContext";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
const ItemCard = (props) => {
  const { authincated, token, TotalCartItems, setTotalCartItems } =
    useContext(authContext);
  const [ShowMessage, setShowMessage] = useState(false);
  const [CartProcessing, setCartProcessing] = useState(false);
  const [Message, setMessage] = useState("");
  const cartMessage = () => {
    setShowMessage(true);
  };

  //Add to cart button
  const AddToCart = () => {
    const handleAddToCart = async () => {
      await setMessage("");
      await setCartProcessing(true);
      await setShowMessage(false);
      let headersList = {
        Accept: `*/*`,
        "auth-token": `${token}`,
      };
      let request = await fetch(
        `http://localhost:3001/api/auth/cart/${props.itemID}`,
        {
          method: "POST",
          headers: headersList,
        }
      );
      request = await request.json();
      setShowMessage(true);
      setMessage(request.message);
      setCartProcessing(false);
      if (request.sucess) {
        setTotalCartItems(TotalCartItems + 1);
      }
    };

    return (
      <>
        <div className="flex items-center">
          <button
            onClick={authincated ? handleAddToCart : cartMessage}
            className={`${
              authincated ? "bg-indigo-500" : "bg-indigo-400 cursor-not-allowed"
            } outline-none text-white rounded-3xl font-semibold p-1 m-1 w-36 h-11 `}
          >
            <FontAwesomeIcon icon={faCartPlus} /> &nbsp; Add To cart
          </button>
          {CartProcessing && (
            <CircularProgress color="inherit" disableShrink size={20} />
          )}
        </div>
        {ShowMessage ? (
          <>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              <p
                className="shadow relative w-48 text-sm"
                style={{ top: "-20px", backgroundColor: "#ffc069a3" }}
              >
                {!authincated ? "Login to add items in cart" : Message  }
              </p>
            </motion.div>
          </>
        ) : (
          <div className="w-48">&nbsp;</div>
        )}
      </>
    );
  };

  return (
    <div className="m-2">
      <div
        style={{ margin: "0 auto" }}
        className="  sm:flex block flex-col w-72 sm:mx-2 shadow hover:shadow-2xl duration-500 rounded-xl"
      >
        <div>{  props.discountStatus && <Tag name={props.tagname} />}</div>
        <div className="h-48 w-72">
          <LazyLoadImage
            src={props.thumbnail}
            effect="blur"
            alt="thumbnail"
            className="h-48 w-72 rounded-t-lg transform duration-300 hover:scale-110 hover:shadow"
          />
        </div>
        <div className="mx-2 mt-1 ">
          <div>
            <Rating value={props.rating} readOnly={true} precision={0.1} />
            <span className="text-base">({props.totalReview})</span>
          </div>
          <div className="text-lg hover:text-indigo-500"><Link to={"/item/"+props.slung}>{props.title}</Link></div>
          <div className="hover:text-indigo-500"><Link to={"/item/"+props.slung}>{props.discription.substring(0, 80) + "..."}</Link></div>
          <div className="text-lg">
            <span className="text-red-500 text-base">
              {currencySymb.currencySymb}{" "}
            </span>
            {props.discount === 0 ? (
              <span className="text-green-500">{props.price}</span>
            ) : (
              <>
                <span className="text-gray-500 line-through">
                  {props.price}
                </span>
                {
                  <span className="text-green-500">
                    &nbsp; {props.price - (props.price * props.discount) / 100}
                  </span>
                }
              </>
            )}
          </div>
          <AddToCart />
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
