import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import ItemCard from "../components/cards/itemsCard";
import Carousel from "react-multi-carousel";
import Backhost from "../required";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import orginationName from "../required";
import authContext from "../context/auth/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import {
  faTruck,
  faDollarSign,
  faTag,
  faUsers,
  faThList,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import CardSkeletion from "./Skeleton";
// import AwesomeSlider from "react-awesome-slider";
const Home = () => {
  const { token, authincated } = useContext(authContext);
  const [Items, setItems] = useState([]);
  const [Recommendation, setRecommendation] = useState([]);
  const [DisItems, setDisItems] = useState([]);
  const [DisItemsSucess, setDisItemsSucess] = useState(false);
  const [RecSucess, setRecSucess] = useState(false);
  const [LatestItemSucess, setLatestItemSucess] = useState(false);
  const [CategoryList, setCategoryList] = useState([]);
  //fetching latest items
  const getItems = async () => {
    const response = await fetch(Backhost.Backhost + "/api/items/latest");
    const data = await response.json();
    await setItems(data.items);
    await setLatestItemSucess(true);
  };

  // fetching latest in discount items
  const getDisItems = async () => {
    const response = await fetch(Backhost.Backhost + "/api/items/discounted");
    const data = await response.json();
    await setDisItems(data.items);
    await setDisItemsSucess(true);
  };

  const getRecommendation = async () => {
    let headersList = {
      Accept: "*/*",
      "auth-token": `${token}`,
    };

    let data = await fetch(
      Backhost.Backhost + "/api/auth/user/recommendation",
      {
        method: "GET",
        headers: headersList,
      }
    );
    data = await data.json();
    setRecommendation(data);
    setRecSucess(data.sucess);
  };

  const getCategory = async () => {
    let headersList = {
      Accept: "*/*",
    };

    let data = await fetch(`${Backhost.Backhost}/api/items/category`, {
      method: "GET",
      headers: headersList,
    });
    data = await data.json();
    await setCategoryList(data.categories);
  };

  useEffect(() => {
    getItems();
    getDisItems();
    getCategory();
    // eslint-disable-next-line
  }, [authincated]);
  useEffect(() => {
    // getRecommendation();
    // eslint-disable-next-line
  }, []);
  // responsive image slider
  const Imageresponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 658 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 658, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  //responsonsive latest items slider
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1294 },
      items: 4,
      slidesToSlide: 4,
    },
    laptop: {
      breakpoint: { max: 1294, min: 1000 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1000, min: 568 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 568, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // responsive category slider
  const CategoryResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1294 },
      items: 8,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1294, min: 1000 },
      items: 6,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1000, min: 568 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 568, min: 0 },
      items: 3,
      slidesToSlide: 1,
    },
  };
  return (
    <>
      {/* helmet for providing title and meta tags */}
      <Helmet>
        <title>Home - {orginationName.orginationName}</title>
      </Helmet>

      {/* image slider  */}
      <div className="home-image-slider m-1 shadow">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          responsive={Imageresponsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
        >
          <div className="home-image-slide">
            <img src={Backhost.Backhost + "/static/f1.png"} alt="" />
          </div>
          <div className="home-image-slide">
            <img src={Backhost.Backhost + "/static/f2.jpg"} alt="" />
          </div>
          <div className="home-image-slide">
            <img src={Backhost.Backhost + "/static/f3.jpg"} alt="" />
          </div>
        </Carousel>
      </div>

      <div
        className="home-facility flex flex-row flex-wrap justify-around items-center text-white  "
        style={{ background: "#252525", minHeight: "40px" }}
      >
        <div className="home-facility-1 p-2">
          <FontAwesomeIcon icon={faTruck} /> <span>Free Shiping </span>
        </div>
        <div className="home-facility-2 p-2">
          <FontAwesomeIcon icon={faTag} /> <span>100% Quality product</span>
        </div>
        <div className="home-facility-3 p-2">
          <FontAwesomeIcon icon={faDollarSign} />{" "}
          <span>Lowest Price Gurantee</span>
        </div>
        <div className="home-facility-4 p-2">
          <FontAwesomeIcon icon={faUsers} /> <span>24/7 Support</span>
        </div>
      </div>
      <div className="mx-2 ml-6 my-1">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          arrows={false}
          infinite={true}
          responsive={CategoryResponsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          keyBoardControl={true}
          transitionDuration={1000}
          containerClass="carousel-container "
        >
          {CategoryList.map((data) => {
            return (
              <Link
                to={"/category/" + data.value}
                key={data._id}
                className="block mx-auto my-0"
              >
                <div className="p-1 bg-indigo-500 w-min text-white hover:bg-indigo-600 rounded">
                <span className="flex items-center justify-center">
                  <b className="mx-1"> {data.value}</b> ({data.total})
                  <FontAwesomeIcon className="mx-1" icon={faArrowRight} /> 
                  </span>
                </div>
              </Link>
            );
          })}
        </Carousel>
      </div>
      {/* latest items slider */}
      <div className="mx-1 md:mx-4 p-2 shadow">
        <h2 className="md:text-3xl text-2xl ml-4 my-1 font-bold font-mono">
          <FontAwesomeIcon icon={faThList} /> Latest products
        </h2>
        {LatestItemSucess ? (
          <>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              transitionDuration={1000}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
            >
              {Items.map((itemsData) => {
                return (
                  <div key={itemsData._id}>
                    <ItemCard
                      slung={itemsData.slung}
                      thumbnail={itemsData.thumbnail}
                      itemID={itemsData._id}
                      title={itemsData.name}
                      rating={itemsData.review.average}
                      discountStatus={itemsData.discount.status}
                      totalReview={itemsData.review.total}
                      discription={itemsData.discription}
                      price={itemsData.price}
                      discount={itemsData.discount.pst}
                      tagname={
                        itemsData.discount.status
                          ? itemsData.discount.pst + "% off"
                          : ""
                      }
                    />
                  </div>
                );
              })}
            </Carousel>
          </>
        ) : (
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            transitionDuration={1000}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
          >
            <div>
              <CardSkeletion />
            </div>
            <div>
              <CardSkeletion />
            </div>
            <div>
              <CardSkeletion />
            </div>
            <div>
              <CardSkeletion />
            </div>
          </Carousel>
        )}
      </div>

      <Divider />
      <div className="mx-1 my-5 md:mx-4 p-2 shadow">
        <h2 className="md:text-3xl text-2xl  ml-4 my-1 font-bold font-mono">
          <FontAwesomeIcon icon={faThList} /> Discounted products
        </h2>
        {DisItemsSucess ? (
          <>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              transitionDuration={1000}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
            >
              {DisItems.map((itemsData) => {
                if (itemsData.length !== 0) {
                  return (
                    <div key={itemsData._id}>
                      <ItemCard
                        slung={itemsData.slung}
                        thumbnail={itemsData.thumbnail}
                        itemID={itemsData._id}
                        title={itemsData.name}
                        discountStatus={itemsData.discount.status}
                        rating={itemsData.review.average}
                        totalReview={itemsData.review.total}
                        discription={itemsData.discription}
                        price={itemsData.price}
                        discount={itemsData.discount.pst}
                        tagname={itemsData.discount.pst + "% Off"}
                      />
                    </div>
                  );
                } else {
                  return (
                    <p className="font-bold text-2xl">
                      No items is In offer right now
                    </p>
                  );
                }
              })}
            </Carousel>
          </>
        ) : (
          <>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              transitionDuration={1000}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
            >
              <div>
                <CardSkeletion />
              </div>
              <div>
                <CardSkeletion />
              </div>
              <div>
                <CardSkeletion />
              </div>
              <div>
                <CardSkeletion />
              </div>
            </Carousel>
          </>
        )}
        {authincated && (
          <>
            {RecSucess && (
              <h2 className="md:text-3xl text-2xl  ml-4 my-1 font-bold font-mono">
                <FontAwesomeIcon icon={faThList} /> Recommended products <br />
              </h2>
            )}
            <div className="flex flex-wrap sm:justify-between justify-center">
              <LazyLoadComponent beforeLoad={getRecommendation}>
              {RecSucess ? (
                Recommendation.items.map((itemsData) => {
                  if (itemsData.length !== 0) {
                    return (
                      <div key={itemsData._id}>
                        <ItemCard
                          slung={itemsData.slung}
                          thumbnail={itemsData.thumbnail}
                          itemID={itemsData._id}
                          discountStatus={false}
                          title={itemsData.name}
                          rating={itemsData.review.average}
                          totalReview={itemsData.review.total}
                          discription={itemsData.discription}
                          price={itemsData.price}
                          discount={itemsData.discount.pst}
                          tagname={
                            itemsData.discount.status
                              ? itemsData.discount.pst + "% off"
                              : null
                          }
                        />
                      </div>
                    );
                  } else {
                    return (
                      <p className="font-bold text-2xl">
                        No recommendation right now
                      </p>
                    );
                  }
                })
                ) : (
                  <>
                  <CardSkeletion /> <CardSkeletion /> <CardSkeletion /> <CardSkeletion />
                </>
              )}
              </LazyLoadComponent>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Home;
