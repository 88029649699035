import {
  Alert,
  Avatar,
  CircularProgress,
  Divider,
  Rating,
  Snackbar,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Backhost from "../required";
import authContext from "../context/auth/authContext";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import ReviewSkeleton from "./ReviewSkeleton";
import { LazyLoadComponent } from "react-lazy-load-image-component";
const PostReview = (props) => {
  const { authincated, token, UserDetails } = useContext(authContext);
  const [RatingValue, setRatingValue] = useState(5);
  const [Reviews, setReviews] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Sucess, setSucess] = useState(false);
  const [Message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [ShowDeleteAlert, setShowDeleteAlert] = useState(false);
  const [ProcessingMessage, setProcessingMessage] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const {getData} = props;
  const getReview = async () => {
    await setLoading(true);
    let headersList = {
      Accept: "*/*",
    };

    let reqOptions = {
      url: `${Backhost.Backhost}/api/reviews/${props.id}&page=1`,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    response = response.data;
    await setReviews(response.reviews);
    await setTotalResults(response.total);
    await setSucess(response.sucess);
    await setLoading(false);
  };

  // function to post an new review
  const postReview = async (e) => {
    await setProcessingMessage(true);
    e.preventDefault();
    let headersList = {
      Accept: "*/*",
      "auth-token": token,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${Backhost.Backhost}/api/auth/reviews/${props.id}`,
      method: "POST",
      headers: headersList,
      data: `{"review": ${RatingValue},   "comment": "${Message}"}`,
    };

    let response = await axios.request(reqOptions);
    response = response.data;
    if (response.sucess) {
      getReview();
      getData();
    }
    await setProcessingMessage(false);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const UserAvatar = (user) => {
    function stringToColor(string) {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = "#";

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    }

    function stringAvatar(name) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0].toUpperCase()}${name
          .split(" ")[1][0]
          .toUpperCase()}`,
      };
    }
    return (
      <>
        <Avatar {...stringAvatar(user.name)} />
      </>
    );
  };
  //function to delete review
  const deleteReview = async (reviewID) => {
    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
      "auth-token": `${token}`,
    };

    let reqOptions = {
      url: `${Backhost.Backhost}/api/auth/reviews/${reviewID}`,
      method: "DELETE",
      headers: headersList,
    };
    let response = await axios.request(reqOptions);
    response = response.data;
    if (response.sucess) {
      setShowDeleteAlert(true);
      getReview();
      getData();
    }
  };
  //function to fetch more data
  const fetchMoreData = async () => {
    const url = Backhost.Backhost + `/api/reviews/${props.id}&page=${page + 1}`;
    await setPage(page + 1);
    let udata = await axios.get(url);
    let parsedData = await udata.data;
    await setSucess(parsedData.sucess);
    setReviews(Reviews.concat(parsedData.reviews));
  };

  // get ago time
  function TimeSince(data) {
    var date = new Date(data.date);
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return (
        Math.floor(interval) + ` year${Math.floor(interval) > 1 && "s"} ago`
      );
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return (
        Math.floor(interval) +
        ` month${Math.floor(interval) > 1 ? "s" : ""} ago`
      );
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return (
        Math.floor(interval) + ` day${Math.floor(interval) > 1 ? "s" : ""} ago`
      );
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return (
        Math.floor(interval) + ` hour${Math.floor(interval) > 1 ? "s" : ""} ago`
      );
    }
    interval = seconds / 60;
    if (interval > 1) {
      return (
        Math.floor(interval) +
        ` minute${Math.floor(interval) > 1 ? "s" : ""} ago`
      );
    }
    return Math.floor(seconds) + ` seconds ago`;
  }
  return (
    <div className="shadow-md overflow-hidden">
      <motion.div
        initial={{ translateX: 1000, scale: 0.8 }}
        animate={{ translateX: 0, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 30,
        }}
      >
        <div className="flex flex-col ease-in  transition duration-500">
          {authincated && (
            <>
              <div className="flex ml-3 shadow-md items-center  mx-8 mb-4 ">
                {props.canReview.status ? (
                  <form
                    onSubmit={postReview}
                    className="w-full max-w-xl bg-white rounded-lg px-4 pt-2"
                  >
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <h2 className="px-4 pt-3 pb-2 text-gray-800 text-lg">
                        Post a review about this product
                      </h2>
                      <div className="w-full md:w-full px-3 mb-2 mt-2">
                        <Rating
                          onChange={async (event, newValue) => {
                            if (newValue === null) {
                              setRatingValue(1);
                            } else {
                              setRatingValue(newValue);
                            }
                          }}
                          defaultValue={4}
                        />
                        <textarea
                          className="bg-gray-100 rounded border  border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white"
                          name="body"
                          placeholder="Type your review here"
                          onChange={handleMessageChange}
                          required
                          value={Message}
                        ></textarea>
                      </div>
                      <div className="w-full flex items-start md:w-full px-3">
                        <div className="-mr-1 float-right">
                          <input
                            type="submit"
                            className="bg-indigo-500 cursor-pointer text-white font-medium py-1 px-4 border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-indigo-600"
                            value="Post Review"
                          />
                          {ProcessingMessage && <CircularProgress size={20} />}
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <p className="m-1 font-bold">
                    {props.canReview.message}
                  </p>
                )}
              </div>
            </>
          )}

          <div>
            <div className="flex flex-col">
              <LazyLoadComponent beforeLoad={getReview}>
                {Loading ? (
                  <>
                    <ReviewSkeleton /> <ReviewSkeleton />
                    <div className="text-center">
                      <CircularProgress />
                    </div>
                  </>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={Sucess && Reviews.length}
                      next={Sucess && fetchMoreData}
                      hasMore={Sucess && Reviews.length !== totalResults}
                      loader={
                        <>
                          <ReviewSkeleton /> <ReviewSkeleton />{" "}
                          <div className="text-center">
                            <CircularProgress />
                          </div>
                        </>
                      }
                      endMessage={
                        Sucess && (
                          <p className="text-center my-2">
                            <b>Yay! You have seen it all</b>
                          </p>
                        )
                      }
                    >
                      {Sucess &&
                        Reviews.map((data) => {
                          return (
                            <div key={data._id}>
                              <div className=" bg-white p-2 pt-4 rounded ">
                                <div className="flex flex-row justify-between ml-3">
                                  <div className="flex">
                                    <div className="mr-3">
                                      {Sucess && (
                                        <UserAvatar name={data.userName} />
                                      )}
                                      <span>{data.edited && "(Edited)"}</span>
                                    </div>

                                    <div>
                                      <h1 className="font-semibold">
                                        {data.userName}
                                      </h1>
                                      <p className="text-xs text-gray-500 ">
                                        <TimeSince date={Sucess && data.date} />
                                      </p>
                                    </div>
                                  </div>
                                  {UserDetails.id === data.userID && (
                                    <div>
                                      <span
                                        onClick={() => {
                                          deleteReview(data._id);
                                        }}
                                        className="text-red-400 cursor-pointer hover:text-red-500 m-1"
                                      >
                                        Delete
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="mt-1 p-3 w-full">
                                  <Rating
                                    size="small"
                                    readOnly
                                    value={Sucess && data.review}
                                  />
                                  <div className="border-t p-2 rounded ">
                                    <p>{Sucess && data.comment}</p>
                                  </div>
                                </div>

                                <div className="flex justify-between mx-3"></div>
                              </div>
                              <Divider />
                            </div>
                          );
                        })}
                    </InfiniteScroll>
                    {totalResults === undefined && (
                      <p className="text-center my-2">
                        <b>No reviews found</b>
                      </p>
                    )}
                  </>
                )}
              </LazyLoadComponent>
            </div>
          </div>
        </div>

        {/* all snackbar are here */}
        <Snackbar
          open={ShowDeleteAlert}
          autoHideDuration={6000}
          onClose={() => {
            setShowDeleteAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowDeleteAlert(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Review Deleted sucessfully
          </Alert>
        </Snackbar>
      </motion.div>
    </div>
  );
};

export default PostReview;
