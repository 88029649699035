import React, {useState} from 'react'
import Sucess from "../components/alerts/Sucess";
import Error from "../components/alerts/Error";
import "./forgetPassword.css"
import { Helmet } from 'react-helmet';
import Backhost from "../required"
const ReqforgetPassword = () => {
    const [SucessST, setSucessST] = useState(false);
    const [errorST, seterrorST] = useState(false);
    const [Message, setMessage] = useState("");
    const [credentials, setCredentials] = useState({
     email: ""
    });

    const handleSubmit = async (e)=>{
        e.preventDefault();
        await setSucessST(false);
        await seterrorST(false);
        await setMessage("");

        if (credentials.email.indexOf("@") <= -1) {
            seterrorST(true);
            setSucessST(false);
            setMessage("Invalid Email Address");
          }
          else{
            let headersList = {
                "Accept": "*/*",
                "Content-Type": "application/json",
               } 
             let  result = await fetch(Backhost.Backhost + "/forgetpassword", { 
                 method: "POST",
                 body: `{"email":"${credentials.email}"}`,
                 headers: headersList
               })
               result = await result.json()
            if (result.sucess) {
                seterrorST(false);
                setSucessST(true);
                setMessage(result.message);
            }
            else{
                seterrorST(true);
                setSucessST(false);
                setMessage(result.message);
            }
          }
    }
    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
      };

    return (
        <div>
          <Helmet>
            <title>Forget Password</title>
          </Helmet>
            <form onSubmit={handleSubmit}>
          <div className="px-16 pt-4 h-7 mb-20">
            {SucessST ? <Sucess message={Message} /> : ""}
            {errorST ? <Error message={Message} /> : ""}
          </div>
          <div>
            <div class="container" style={{justifyContent: "center"}}>
              <div class="inputs">
                <h2>Forget Password?</h2>
                <p>Enter email to rest password</p>
                <div class="input">
                  <input
                    onChange={onChange}
                    type="text"
                    name="email"
                    id="password"
                  />
                  <label htmlFor="password">Email</label>
                </div>
              </div>
              <button className="forget-button">Reset</button>
            </div>
          </div>
        </form>
        </div>
    )
}

export default ReqforgetPassword
