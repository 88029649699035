import React, { useState } from "react";
import { motion } from "framer-motion";
import Backhost from "../../required";
import { Alert } from "@mui/material";
const ChangePassword = (props) => {
  const { settoken } = props;
  const [CurrentPss, setCurrentPss] = useState("");
  const [NewPss, setNewPss] = useState("");
  const [CheckPss, setCheckPss] = useState("");
  const [Success, setSuccess] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [Message, setMessage] = useState("");
  const Password = async (e) => {
    e.preventDefault();
    if (CurrentPss === "" || NewPss === "" || CheckPss === "") {
      await setShowAlert(false);
      await setSuccess(false);
      await setMessage("Some Required Field Are Empty");
      await setShowAlert(true);
    } else if (NewPss !== CheckPss) {
      await setShowAlert(false);
      await setSuccess(false);
      await setMessage("New Password and Retyped password does't match ");
      await setShowAlert(true);
    } else if (NewPss.length < 5) {
      await setShowAlert(false);
      await setSuccess(false);
      await setMessage("Password must be 6 charecter long");
      await setShowAlert(true);
    } else {
      let headersList = {
        Accept: "*/*",
        "auth-token": `${props.token}`,
        "Content-Type": "application/json",
      };

      let data = await fetch(
        `${Backhost.Backhost}/api/auth/user/setting/password`,
        {
          method: "PUT",
          body: `{\n    "Prepassword": "${CurrentPss}",\n    "password": "${NewPss}"\n}`,
          headers: headersList,
        }
      );
      data = await data.json();
      if (data.sucess) {
        await setShowAlert(false);
        await setSuccess(true);
        await setMessage(
          "Password changed sucessfully, You will get Logged out soon"
        );
        await setShowAlert(true);
        setTimeout(function () {
          settoken("");
          localStorage.removeItem("token");
        }, 5000);
      } else {
        await setShowAlert(false);
        await setSuccess(false);
        await setMessage(data.message);
        await setShowAlert(true);
      }
    }
  };

  return (
    <div className="overflow-hidden">
      <motion.div
        initial={{ translateX: -1000, scale: 0.8 }}
        animate={{ translateX: 0, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 30,
        }}
      >
        <div className="max-w-sm h-16 mx-auto my-2">
          {ShowAlert && (
            <motion.div
              initial={{ translateY: -1000, scale: 0.8 }}
              animate={{ translateY: 0, scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 30,
              }}
            >
              <Alert severity={Success ? "success" : "error"}>{Message}</Alert>
            </motion.div>
          )}
        </div>
        <form onSubmit={Password}>
          <div class="flex justify-center h-screen ">
            <div class=" py-6 rounded-md px-10 h-80 shadow-md">
              <h1 class="text-center text-lg font-bold text-gray-500">
                Change Password
              </h1>
              <div class="space-y-4 mt-6">
                <div class="w-full">
                  <input
                    type="password"
                    placeholder="Current Password"
                    value={CurrentPss}
                    onChange={(e) => {
                      setCurrentPss(e.target.value);
                    }}
                    class="px-4 py-2 bg-gray-50"
                  />
                </div>
                <div class="w-full">
                  <input
                    type="password"
                    value={NewPss}
                    onChange={(e) => {
                      setNewPss(e.target.value);
                    }}
                    placeholder="New Password"
                    class="px-4 py-2 bg-gray-50"
                  />
                </div>
                <div class="w-full">
                  <input
                    type="password"
                    value={CheckPss}
                    onChange={(e) => {
                      setCheckPss(e.target.value);
                    }}
                    placeholder="Type Password again"
                    class="px-4 py-2 bg-gray-50"
                  />
                </div>
              </div>
              <button class="w-full mt-5 bg-indigo-600 text-white py-2 rounded-md font-semibold tracking-tight">
                Change Password
              </button>
            </div>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default ChangePassword;
