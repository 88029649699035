import {
  faShippingFast,
  faUser,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import authContext from "../context/auth/authContext";
import BillingAddress from "./billingaddress/BillingAddress";
import { useHistory } from "react-router-dom";
import ChangePassword from "./password/ChangePassword";
const User = () => {
  const history = useHistory();
  const [Nav, setNav] = useState(1);
  const {  settoken, tokenLoading, authincated, token } =useContext(authContext);

  const redirect = () => {
    history.push("/login");
  };
  return (
    <div>
      {tokenLoading ? (
        " "
      ) : authincated ? (
        <>
          <Helmet>
            <title>User- Setting</title>
          </Helmet>
          <div className="flex flex-col mt-3">
            <div className="flex m-3 flex-wrap">
              <li className="hover:bg-gray-300 list-none">
                <BottomNavigation showLabels>
                  <BottomNavigationAction
                    label="User"
                    icon={<FontAwesomeIcon icon={faUser} />}
                    onClick={() => {
                      setNav(0);
                    }}
                  />
                  <BottomNavigationAction
                    label="Address "
                    icon={<FontAwesomeIcon icon={faShippingFast} />}
                    onClick={() => {
                      setNav(1);
                    }}
                  />
                  <BottomNavigationAction
                    label="Password"
                    icon={<FontAwesomeIcon icon={faUserLock} />}
                    onClick={() => {
                      setNav(3);
                    }}
                  />
                </BottomNavigation>
              </li>
            </div>
            <div>
              {Nav === 0 ? (
                ""
              ) : Nav === 1 ? (
                <BillingAddress token={token} Nav={Nav} />
              ) : (
                <ChangePassword settoken={settoken} token={token} />
              )}
            </div>
          </div>{" "}
        </>
      ) : (
        redirect()
      )}
    </div>
  );
};

export default User;
