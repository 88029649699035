import React, { useState, useEffect } from "react";
import axios from "axios";
import Backhost from "../required";
import { CircularProgress } from "@mui/material";
const Address = (props) => {
  const { token, setSelectedAddress } = props;
  const [Addresses, setAddresses] = useState([]);
  const [Sucess, setSucess] = useState(false);
  const [Loading, setLoading] = useState(true);

  const getAddresses = async () => {
    await setSucess(false);
    await setLoading(true);
    let headersList = {
      Accept: "*/*",
      "auth-token": `${token}`,
    };

    let reqOptions = {
      url: `${Backhost.Backhost}/api/auth/user/setting/address`,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    await setAddresses(response.data.addresses);
    await setSucess(response.data.sucess);
    await setLoading(false);
  };

  useEffect(() => {
    getAddresses();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="my-2">
      <label className="font-medium inline-block mb-3 text-sm uppercase">
        Shipping
      </label>
      <select className="block p-2 text-gray-600 w-full text-sm">
        <option selected disabled>
          Select address
        </option>
        {Loading ? (
          <CircularProgress />
        ) : (
          <>
            {Sucess ? (
              Addresses.map((data) => {
                return (
                  <option
                    onClick={() => {
                      setSelectedAddress(data._id);
                    }}
                    key={data._id}
                  >
                    {data.fullname}, {data.phonenumber},{data.province},{data.city}
                  </option>
                );
              })
            ) : (
              <p>No address found</p>
            )}
          </>
        )}
      </select>
    </div>
  );
};

export default Address;
