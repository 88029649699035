import { Divider,  Slider, Checkbox } from "@mui/material";
import React, { useState } from "react";

const Filter = (props) => {
  const { maxPrice, setmaxPrice, SliderValue, setSliderValue } = props;

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };
  return (
    <div className="w-80 sticky">
      <Divider />
      <div>
        <div className="">
          <p className="font-bold"> Price less then: </p>
          <input
            type="number"
            className="border-2"
            value={SliderValue}
            onChange={(e) => {
              setSliderValue(e.target.value);
            }}
          />
        </div>
        <div style={{ width: "95%" }}>
           <Slider
            onChange={handleSliderChange}
            max={maxPrice}
            value={SliderValue}
            aria-label="Default"
            valueLabelDisplay="auto"
          />
        </div>
      </div>
      <Divider />
      <div>
      <Checkbox /> Discounted
      </div>
    </div>
  );
};

export default Filter;
