import { CircularProgress, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import authContext from "../context/auth/authContext";
import currencySymb from "../required";
import axios from "axios";
import Backhost from "../required";
import orginationName from "../required";
import { Helmet } from "react-helmet";
import NothingInCart from "../images/NothingInCart.png";
import Address from "./Address";

const Cart = () => {
  const [SelectedAddress, setSelectedAddress] = useState("")
  const {
    authincated,
    tokenLoading,
    token,
    CartItems,
    TotalCartItems,
    setTotalCartItems,
    CartLoading,
  } = useContext(authContext);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (TotalCartItems >= 1) {
    const Items = (props) => {
      const [ItemData, setItemData] = useState({ sucess: false });
      const [Quantity, setQuantity] = useState(props.quantity);
      const [Deleting, setDeleting] = useState(false);
      // get specific items details
      const GetItemsDetails = async () => {
        let headersList = {
          Accept: "*/*",
        };

        let reqOptions = {
          url: `${Backhost.Backhost}/api/items/details/${props.id}`,
          method: "GET",
          headers: headersList,
        };

        let response = await axios.request(reqOptions);
        setItemData(response.data);
      };

      const handleminusQuantity = () => {
        setQuantity(Quantity - 1);
      };
      const handleaddQuantity = () => {
        setQuantity(Quantity + 1);
      };

      const handleCartItemDelete = async () => {
        await setDeleting(true);
        let headersList = {
          Accept: "*/*",
          "auth-token": `${token}`,
        };

        let reqOptions = {
          url: `${Backhost.Backhost}/api/auth/cart/${props.id}`,
          method: "DELETE",
          headers: headersList,
        };

        let response = await axios.request(reqOptions);
        response = response.data;
        if (response.sucess) {
          setTotalCartItems(TotalCartItems - 1);
        }
        await setDeleting(false);
      };

      const handleQuantityChange = (e) => {
        if (parseInt(e.target.value) >= 1) {
          setQuantity(parseInt(e.target.value));
        } else {
          setQuantity(1);
        }
      };

      function doNothing() {}

      useEffect(() => {
        GetItemsDetails();
        // eslint-disable-next-line
      }, []);
      return (
        <>
          <Helmet>
            <title>Cart -{orginationName.orginationName}</title>
          </Helmet>
          {ItemData.sucess ? (
            <>
              <div className="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5">
                <div className="flex w-2/5">
                  <div className="flex flex-col justify-between ml-4 flex-grow">
                    <Link to={`/item/${ItemData.item.slung}`}>
                      <span className="font-bold text-sm hover:text-indigo-500 cursor-pointer">
                        {ItemData.item.name}
                      </span>
                    </Link>
                    <span className="text-gray-500 text-xs">
                      {ItemData.item.discription.substring(0, 80) + "..."}
                    </span>
                    <span
                      onClick={handleCartItemDelete}
                      className=" inline cursor-pointer font-semibold text-indigo-500 hover:text-indigo-600 text-xs"
                    >
                      Remove &nbsp;
                      {Deleting && (
                        <CircularProgress
                          color="primary"
                          disableShrink
                          size={11}
                        />
                      )}
                    </span>
                  </div>
                </div>

                <div className="flex justify-center w-1/5">
                  <Tooltip title="Decrease">
                    <button
                      onClick={Quantity > 1 ? handleminusQuantity : doNothing()}
                    >
                      <svg
                        className="fill-current text-gray-600 w-3 cursor-pointer"
                        viewBox="0 0 448 512"
                      >
                        <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                      </svg>
                    </button>
                  </Tooltip>

                  <input
                    className="mx-2 border text-center w-8"
                    type="text"
                    value={Quantity}
                    onChange={handleQuantityChange}
                  />
                  <Tooltip title="Increase">
                    <button onClick={handleaddQuantity}>
                      <svg
                        className="fill-current text-gray-600 w-3 cursor-pointer"
                        viewBox="0 0 448 512"
                      >
                        <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                      </svg>
                    </button>
                  </Tooltip>
                </div>
                <span className="text-center w-1/5 font-semibold text-sm">
                  {currencySymb.currencySymb}
                  {!ItemData.item.discount.status ? (
                    <span className="text-green-500">
                      {numberWithCommas(ItemData.item.price)}
                    </span>
                  ) : (
                    <>
                      <span className="text-gray-500 line-through">
                        {numberWithCommas(ItemData.item.price)}
                      </span>
                      {
                        <span className="text-green-500">
                          &nbsp;
                          <br />
                          {numberWithCommas(
                            ItemData.item.price -
                              (ItemData.item.price *
                                ItemData.item.discount.pst) /
                                100
                          )}
                        </span>
                      }
                    </>
                  )}
                </span>
                <span className="text-center w-1/5 font-semibold text-sm">
                  {currencySymb.currencySymb}
                  <span className="total_price">
                    {numberWithCommas(
                      Quantity *
                        (ItemData.item.price -
                          (ItemData.item.price * ItemData.item.discount.pst) /
                            100)
                    )}
                  </span>
                </span>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      );
    };

    const TotalPrice = () => {
      const [Totalprice, setTotalprice] = useState(0);
      async function getPrice() {
        let priceBe = 0;
        let uData = await document.querySelectorAll(".total_price");
        await Array.prototype.map.call(uData, function (data) {
          priceBe = priceBe + Number(data.textContent.replaceAll(",", ""));
        });
        await setTotalprice(priceBe);
      }
      useEffect(() => {
        getPrice();
        // eslint-disable-next-line
      }, [TotalCartItems, []]);
      setInterval(() => {
        getPrice();
      }, 250);
      return <>{numberWithCommas(Totalprice)}</>;
    };
    return (
      <>
        {tokenLoading ? (
          <div className="text-center mt-3">
            <CircularProgress color="primary" disableShrink size={30} />
          </div>
        ) : authincated ? (
          <div className="mx-auto mt-10">
            <div className="lg:flex block shadow-md my-10">
              <div className="lg:w-3/4 w-auto bg-white px-10 py-10">
                <div className="flex justify-between border-b pb-8">
                  <h1 className="font-semibold text-2xl">Shopping Cart</h1>
                  <h2 className="font-semibold text-2xl">
                    {TotalCartItems} Items
                  </h2>
                </div>
                <div className="flex mt-10 mb-5">
                  <h3 className="font-semibold text-gray-600 text-xs uppercase w-2/5">
                    Product Details
                  </h3>
                  <h3
                    className="
            font-semibold
           text-gray-600 text-xs
            uppercase
            w-1/5
            text-center
          "
                  >
                    Quantity
                  </h3>
                  <h3
                    className="
            font-semibold
            text-gray-600 text-xs
            uppercase
            w-1/5
            text-center
          "
                  >
                    Price
                  </h3>
                  <h3
                    className="
            font-semibold
             text-gray-600 text-xs
            uppercase
            w-1/5
            text-center
          "
                  >
                    Total
                  </h3>
                </div>
                {CartLoading ? (
                  <div className="text-center mt-3">
                    <CircularProgress color="primary" disableShrink size={30} />
                  </div>
                ) : (
                  <>
                    {CartItems.map((itemsData) => {
                      return (
                        <Items
                          key={itemsData._id}
                          id={itemsData.itemID}
                          cartid={itemsData._id}
                          quantity={itemsData.quantity}
                        />
                      );
                    })}
                  </>
                )}

                <Link
                  to="/"
                  className="flex font-semibold text-indigo-600 text-sm mt-10"
                >
                  <svg
                    className="fill-current mr-2 text-indigo-600 w-4"
                    viewBox="0 0 448 512"
                  >
                    <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
                  </svg>
                  Continue Shopping
                </Link>
              </div>

              <div id="summary" className="lg:w-1/4 w-auto px-8 py-10">
                <h1 className="font-semibold text-2xl border-b pb-8">
                  Order Summary
                </h1>
                    <Address token={token} setSelectedAddress={setSelectedAddress}/>
                <div className="py-10">
                  <label
                    htmlFor="promo"
                    className="font-semibold inline-block mb-3 text-sm uppercase"
                  >
                    Promo Code
                  </label>
                  <input
                    type="text"
                    id="promo"
                    placeholder="Enter your code"
                    className="p-2 text-sm w-full"
                  />
                </div>
                <button
                  className="
          bg-indigo-500
          hover:bg-indigo-600
          px-5
          py-2
          text-sm text-white
          uppercase
        "
                >
                  Apply
                </button>
                <div className="border-t mt-8">
                  <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                    <span>Total cost</span>
                    <span>
                      {currencySymb.currencySymb} {<TotalPrice />}
                    </span>
                  </div>
                  <button
                    className="
            bg-indigo-500
            font-semibold
            hover:bg-indigo-600
            py-3
            text-sm text-white
            uppercase
            w-full
          "
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h2 className="text-center mt-2 font-semibold text-2xl">
            login to view cart here
          </h2>
        )}
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Cart -{orginationName.orginationName}</title>
        </Helmet>
        <div className="flex justify-center select-none">
          <img
            className="md:w-2/5 w-full"
            src={NothingInCart}
            alt="nothing in cart"
          />
        </div>
      </>
    );
  }
};

export default Cart;
