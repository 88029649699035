import { faThList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Error404 from "../../components/404";
import ItemCard from "../../components/cards/itemsCard";
import CardSkeletion from "../../home/Skeleton";
import Backhost from "../../required";
const Category = () => {
  const [Items, setItems] = useState({ items: [] });
  const [Sucess, setSucess] = useState(false);
  let { category } = useParams();
  const getItems = async () => {
    let headersList = {
      Accept: "*/*",
    };

    let data = await fetch(
      Backhost.Backhost + "/api/items/category/" + category,
      {
        method: "GET",
        headers: headersList,
      }
    );
    data = await data.json();
    if (data.sucess) {
      await setItems(data);
    }
    setSucess(true);
  };
  useEffect(() => {
    getItems();
  }, []);

  return (
    <>
      <Helmet>
        <title>Category: {category}</title>
      </Helmet>
      {Items.items.length !== 0 && (
        <h2 className="md:text-3xl text-2xl  ml-4 my-1 font-bold font-mono">
          <FontAwesomeIcon icon={faThList} />
          Category: {category} <br />
        </h2>
      )}
      <>
        {Sucess ? (
          Items.items.length !== 0 ? (
            <div className="flex flex-wrap sm:justify-between justify-center">
              {Items.items.map((itemsData) => {
                return (
                  <div className="lg:w-1/4" key={itemsData._id}>
                    <ItemCard
                      slung={itemsData.slung}
                      thumbnail={itemsData.thumbnail}
                      itemID={itemsData._id}
                      title={itemsData.name}
                      rating={itemsData.review.average}
                      totalReview={itemsData.review.total}
                      discription={itemsData.discription}
                      price={itemsData.price}
                      discountStatus={false}
                      discount={itemsData.discount.pst}
                      tagname={itemsData.discount.pst + "% off"}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <Error404 />
          )
        ) : (
          <div className="flex flex-wrap sm:justify-between justify-center">
            <div className="lg:w-1/4">
              <CardSkeletion />
            </div>
            <div className="lg:w-1/4">
              <CardSkeletion />
            </div>
            <div className="lg:w-1/4">
              <CardSkeletion />
            </div>
            <div className="lg:w-1/4">
              <CardSkeletion />
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default Category;
