import React, { useState,  useContext } from "react";
import { Link } from "react-router-dom";
import SignUpImage from "../images/login.png";
import { Helmet } from "react-helmet";
import Sucess from "../components/alerts/Sucess";
import Error from "../components/alerts/Error";
import Loading from "../components/loading";
import { useHistory } from "react-router-dom";
import authContext from "../context/auth/authContext";
import Backhost from "../required";
const SignUp = () => {
  let history = useHistory()
  const { authincated } = useContext(authContext);
  const [SucessST, setSucessST] = useState(false);
  const [errorST, seterrorST] = useState(false);
  const [Message, setMessage] = useState("");
  const [LodingST, setLodingST] = useState(false)
  const [credentials, setCredentials] = useState({
    username: "",
    email: "",
    password: "",
    checkPassword: "",
  });
  if (authincated) {
    history.push("/")
  }
  const submitSignup = async (e) => {
    e.preventDefault();
    await setSucessST(false);
    await seterrorST(false);
    await setMessage("");

    if (credentials.username.length < 3 || credentials.username.indexOf(" ") <= -1 ) {
      seterrorST(true);
      setSucessST(false);
      setMessage("Invalid Name");
    } else if (credentials.email.indexOf("@") <= -1) {
      seterrorST(true);
      setSucessST(false);
      setMessage("Invalid Email Address");
    } else if (
      credentials.password === "" &&
      credentials.checkPassword === ""
    ) {
      setSucessST(false);
      seterrorST(true);
      setMessage("Password is required");
    } else if (credentials.password !== credentials.checkPassword) {
      setSucessST(false);
      seterrorST(true);
      setMessage("Password doesn't match");
    } else if (credentials.password.length < 6) {
      setSucessST(false);
      seterrorST(true);
      setMessage("Password must be 6 charecter long");
    } else {
      await setLodingST(true)
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let response = await fetch(`${Backhost.Backhost}/api/user/create`, {
        method: "POST",
        body: `{
          "name": "${credentials.username}",
          "email": "${credentials.email}",
          "password": "${credentials.password}"
      }`,
        headers: headersList,
      });
      response = await response.json()
      if (response.sucess) {
        setLodingST(false)
        setSucessST(true);
        seterrorST(false);
        setMessage(response.message);
        setTimeout(function() { history.push("/login");; }, 5000);
      }
      else{
        setLodingST(false)
        setSucessST(false);
        seterrorST(true);
        setMessage(response.message);
      }
    }
  };
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>

      <div className="lg:flex">
        <div className="lg:w-1/2 xl:max-w-screen-sm">
          <div className="px-16 pt-4 h-7 mb-20">
            {SucessST ? <Sucess message={Message} /> : ""}
            {errorST ? <Error message={Message} /> : ""}
          </div>
          <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
            <h2
              className="text-center text-4xl text-indigo-900 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
            >
              Sign Up
            </h2>
            <div className="mt-12">
              <form onSubmit={submitSignup}>
                <div>
                  <div className="text-sm font-bold text-gray-700 tracking-wide">
                    Full Name
                  </div>
                  <input
                    id="username"
                    name="username"
                    autocomplete="username"
                    value={credentials.username}
                    onChange={onChange}
                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="text"
                    placeholder="Example Paudel"
                  />
                </div>
                <div className="mt-8">
                  <div className=" text-sm font-bold text-gray-700 tracking-wide">
                    Email Address
                  </div>
                  <input
                    id="email"
                    name="email"
                    autocomplete="email"
                    value={credentials.email}
                    onChange={onChange}
                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type=""
                    placeholder="example@gmail.com"
                  />
                </div>
                <div className="mt-8">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                      Password
                    </div>
                  </div>
                  <input
                    id="password"
                    name="password"
                    autocomplete="password"
                    value={credentials.password}
                    onChange={onChange}
                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="password"
                    placeholder="Enter your password"
                  />
                </div>
                <div className="mt-8">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                      Enter Password Again
                    </div>
                  </div>
                  <input
                    id="checkPassword"
                    name="checkPassword"
                    autocomplete="password-again"
                    value={credentials.checkPassword}
                    onChange={onChange}
                    className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="password"
                    placeholder="Enter your password again"
                  />
                </div>
                <div className="mt-10">
                  <button
                    className="bg-indigo-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
                  >
                    <div className="flex content-center justify-center">
                    
                    {LodingST ? (
                        <Loading />
                      ) : (
                        <span style={{ padding: "3.5px" }}>Sign Up</span>
                      )}
                    </div>
                  </button>
                </div>
              </form>
              <div className="mt-4 text-sm font-display font-semibold text-gray-700 text-center">
                Already Have account?
                <Link
                  to="/login"
                  className="cursor-pointer text-indigo-600 hover:text-indigo-800"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen">
          <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
            <img src={SignUpImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
