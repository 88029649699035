import { Skeleton } from "@mui/material";
import React from "react";

const SearchSkeleton = () => {
  return (
    <div
    style={{ margin: "10px auto" }}
      className="sm:flex block items-center md:w-auto w-min shadow p-3 rounded md:flex-row flex-col md:mx-2"
    >
   
        <Skeleton sx={{ height: "250px", width: "300px" }} />
    
      <div className="mx-2">
        <div>
          <div>
            <Skeleton sx={{ height: "40px", width: "250px" }} />
          </div>
          <div>
          <Skeleton sx={{ height: "40px", width: "300px" }} />
          </div>
        </div>
        <div>
    <div className="flex">

        <Skeleton sx={{ height: "30px", width: "80px" }} />&nbsp;&nbsp;<Skeleton sx={{ height: "30px", width: "80px" }} />
    </div>
        </div>
        <div>
          <div className="text-lg">
          <Skeleton sx={{ height: "40px", width: "180px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSkeleton;
