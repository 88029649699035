import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThList } from "@fortawesome/free-solid-svg-icons";
import { Chip, CircularProgress, Divider } from "@mui/material";
import Horizontal from "../components/cards/Horizontal";
import InfiniteScroll from "react-infinite-scroll-component";
import Backhost from "../required";
import { Helmet } from "react-helmet";
import SearchSkeleton from "./SearchSkeleton";
import Filter from "./Filter";
import { motion } from "framer-motion";

const Search = () => {
  const history = useHistory();
  let { quary, category } = useParams();
  // all states
  const [result, setresult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sucess, setsucess] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [resultMessage, setResultMessage] = useState("loading...");
  const [maxPrice, setmaxPrice] = useState();
  const [SliderValue, setSliderValue] = useState(maxPrice);
  const [Fil_Discounted, setFil_Discounted] = useState(false);

  //capitlize first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //fetch result
  const updateresult = async () => {
    const url =
      Backhost.Backhost + `/api/search/${quary}&page=${page}&c=${category}`;
    setLoading(true);
    let data = await fetch(url, {
      method: "POST",
      body: '{"discounted": false}',
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    });

    let parsedData = await data.json();
    await setmaxPrice(parsedData.MaxPrice);
    await setSliderValue(parsedData.MaxPrice);
    setsucess(parsedData.sucess);
    setresult(parsedData.result);
    setTotalResults(parsedData.total);
    setLoading(false);
    if (!parsedData.sucess) {
      setResultMessage("No result found");
    }
  };
  useEffect(() => {
    updateresult();
    setsucess(false);
    // eslint-disable-next-line
  }, [history.location.pathname]);

  //fetch more result
  const fetchMoreData = async () => {
    const url =
      Backhost.Backhost +
      `/api/search/${quary}&page=${page + 1}&c=${category}`;
    setPage(page + 1);
    let data = await fetch(url, {
      method: "POST",
      body: '{\n    "discounted": false,\n    "discounted_P": 10 \n}',
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    });
    let parsedData = await data.json();
    setresult(result.concat(parsedData.result));
    setTotalResults(parsedData.total);
    setsucess(parsedData.sucess);
  };
  return (
    <>
      <Helmet>
        <title>
          Search result for:{" "}
          {capitalizeFirstLetter(quary).replaceAll(/%2F/g, "/")}
        </title>
      </Helmet>

      <div style={{ width: "--webkit-max-content" }} className="shadowxl m-2 ">
        <div>
          <h2 className="text-xl font-semibold">
            <FontAwesomeIcon icon={faThList} /> Search result for:
            {capitalizeFirstLetter(quary).replaceAll(/%2F/g, "/")} (
            {totalResults ? totalResults : 0})
          </h2>
          <Divider />
          <Chip label={capitalizeFirstLetter(category)} />
        </div>
        <div className="flex flex-wrap">
          <div className="shadow-md">
            <Filter
              maxPrice={maxPrice}
              setmaxPrice={setmaxPrice}
              SliderValue={SliderValue}
              setSliderValue={setSliderValue}
            />
          </div>

          {loading && (
            <>
              <SearchSkeleton />
              <SearchSkeleton />
              <SearchSkeleton />
            </>
          )}
          <div className="shadow-md m-1 w-max">
            {/* InfiniteScroll mechanism */}
            <InfiniteScroll
              dataLength={sucess && result.length}
              next={sucess && fetchMoreData}
              hasMore={sucess && result.length !== totalResults}
              loader={
                <>
                  <SearchSkeleton />
                  <div className="text-center h-36">
                    <CircularProgress />
                  </div>
                </>
              }
              endMessage={
                sucess && (
                  <p className="text-center my-2 w-80">
                    <b>Yay! You have seen it all</b>
                  </p>
                )
              }
            >
              <div>
                <div className="overflow-hidden ">
                  {sucess ? (
                    result.map((element) => {
                      if (element.price <= SliderValue) {
                        return (
                          <>
                            <motion.div
                              initial={{ translateX: 1000, scale: 0.8 }}
                              animate={{ translateX: 0, scale: 1 }}
                              className="overflow-hidden"
                              transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 30,
                              }}
                            >
                              <Horizontal
                                key={element._id}
                                slung={element.slung}
                                title={element.name}
                                rating={element.review.average}
                                totalReview={element.review.total}
                                discription={element.discription}
                                price={element.price}
                                thumbnail={element.thumbnail}
                                discount={element.discount.pst}
                              />
                            </motion.div>
                          </>
                        );
                      }
                    })
                  ) : (
                    <div className="text-center px-24 py-36 block">{resultMessage}</div>
                  )}
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
