import React, { useEffect, useState } from "react";
import axios from "axios";
import Backhost from "../../required";

import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const Edit = (props) => {
  const { token, getAddresses, id, setEditMode } = props;
  const [Sucess, setSucess] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [AddSucess, setAddSucess] = useState(false);
  const [Data, setData] = useState({
    fullname: "",
    phonenumber: "",
    province: "",
    city: "",
    extra: "",
  });

  const getDAddress = async () => {
    await setSucess(false);
    await setLoading(true);
    let headersList = {
      Accept: "*/*",
      "auth-token": `${token}`,
    };

    let reqOptions = {
      url: `${Backhost.Backhost}/api/auth/user/setting/address-detail/${id}`,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    if (response.data.sucess) {
      await setData({
        fullname: response.data.address.fullname,
        phonenumber: response.data.address.phonenumber,
        province: response.data.address.province,
        city: response.data.address.city,
        extra: response.data.address.extra,
      });
      await setSucess(response.data.sucess);
      await setLoading(false);
    } else {
      await setLoading(false);
    }
  };

  const onChange = (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const Submit = (e) => {
    e.preventDefault();
    let headersList = {
      Accept: "*/*",
      "auth-token": `${token}`,
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${Backhost.Backhost}/api/auth/user/setting/address/${id}`,
      method: "PUT",
      headers: headersList,
      data: Data,
    };

    axios.request(reqOptions).then(function (response) {
      if (response.data.sucess) {
        setAddSucess(true);
        getDAddress();
        getAddresses();
      }
    });
  };

  useEffect(() => {
    getDAddress();
     // eslint-disable-next-line 
  }, []);
  return (
    <div>
      <h1 className="text-2xl font-medium title-font mb-4 text-gray-900">
        Edit Address:
      </h1>
      {Loading ? (
        <p className="text-center">
          <CircularProgress />
        </p>
      ) : (
        <>
          {Sucess ? (
            <form onSubmit={Submit}>
              <button className="text-indigo-500 hover:text-indigo-600 transition duration-300 " onClick={()=>{setEditMode(false)}}> <FontAwesomeIcon icon={faArrowLeft}/> Go Back</button>
              <div className="flex flex-wrap -m-2">
                <div className="p-2">
                  <div className="relative">
                    <label
                      for="name"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Full Name:
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="fullname"
                      onChange={onChange}
                      value={Data.fullname}
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      required
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      for="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Phone Number: <br />
                    </label>
                    <input
                      type="tel"
                      id="number"
                      name="phonenumber"
                      onChange={onChange}
                      value={Data.phonenumber}
                      className="w-96 bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="p-2">
                  <div className="relative">
                    <label
                      for="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Region/Province <br />
                    </label>
                    <input
                      type="text"
                      id="province"
                      name="province"
                      onChange={onChange}
                      value={Data.province}
                      className="w-96 bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      required
                    />
                  </div>
                </div>
                <div className="p-2">
                  <div className="relative">
                    <label
                      for="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      City: <br />
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      onChange={onChange}
                      value={Data.city}
                      className="w-96 bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="p-2 w-full">
                <div class="relative">
                  <label for="message" class="leading-7 text-sm text-gray-600">
                    Detailed Address <br />
                  </label>
                  <textarea
                    id="extra"
                    name="extra"
                    onChange={onChange}
                    value={Data.extra}
                    placeholder="Eg: New Baneshwor, street number: 101E, Apt: 24, floor: 6, West"
                    class="w-96 bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="p-2 ">
                <button className="flex  text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                  Edit
                </button>
              </div>
            </form>
          ) : (
            <p className="text-center">Failed to get address detail</p>
          )}
        </>
      )}
      <Snackbar
        open={AddSucess}
        autoHideDuration={5000}
        onClose={() => {
          setAddSucess(false);
        }}
      >
        <Alert
          onClose={() => {
            setAddSucess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Address Edited sucessfully
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Edit;
