import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { motion } from "framer-motion";
const Sucess = (props) => {
  return (
    <div>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {props.message}
        </Alert>
      </motion.div>
    </div>
  );
};

export default Sucess;
