import React, { useState, useEffect } from "react";
import AuthContext from "./authContext";
import Backhost from "../../required";

const AuthState = (props) => {
  const [authincated, setauthincated] = useState(false);
  const [token, settoken] = useState(localStorage.getItem("token"));
  const [tokenLoading, settokenLoading] = useState(true);
  const [CartItems, setCartItems] = useState({});
  const [CartLoading, setCartLoading] = useState(true)
  const [TotalCartItems, setTotalCartItems] = useState(0)
  const [AuthResponse, setAuthResponse] = useState({})  
  const [UserDetails, setUserDetails] = useState({sucess:false});
  const [TotalNotification, setTotalNotification] = useState(0)
  // authincate auth token
  const checkAuthincate = async () => {
    await settokenLoading(true);
    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let response = await fetch(Backhost.Backhost + "/verify/usertoken", {
      method: "POST",
      body: `{"token": "${token}"}`,
      headers: headersList,
    });
    response = await response.json();
    await setauthincated(response.sucess);
   await settokenLoading(false);
   await setAuthResponse(response)
  };

  // only authincated user functions
  const getCartItems = async () => {
    await setCartLoading(true)
    let headersList = {
      Accept: "*/*",
      "auth-token": `${token}`,
    };

    let response = await fetch(Backhost.Backhost + "/api/auth/cart/", {
      method: "GET",
      headers: headersList,
    });
    response = await response.json();
    await setCartItems(response.items);
    await setTotalCartItems(response.total)
    await setCartLoading(false)
  };

  const getUserDetails = async () => {
    let data = await fetch(Backhost.Backhost + "/api/auth/user/details", {
      method: "GET",
      headers: {
        "auth-token": `${token}`,
      },
    });
    data = await data.json();
    await setUserDetails(data);
    setTotalNotification(data.totalNotification)
  };

  useEffect(() => {
    checkAuthincate();
    getUserDetails();
    getCartItems();
    // eslint-disable-next-line
  }, [token]);
  useEffect(() => {
    getCartItems();
    // eslint-disable-next-line
  }, [TotalCartItems])
  return (
    // eslint-disable-next-line
    <AuthContext.Provider
      value={{
        token,
        authincated,
        settoken,
        tokenLoading,
        CartItems,
        AuthResponse,
        TotalCartItems,
        setTotalCartItems,
        UserDetails,
        CartLoading,
        TotalNotification,
        setTotalNotification
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
