import {
  CircularProgress,
  Rating,
  Tooltip,
  Breadcrumbs,
  Typography,
  Divider,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import currencySymb from "../required";
import Error404 from "../components/404";
import authContext from "../context/auth/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import ImageMagnifier from "./imageMag";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ItemSkeleton from "./itemSkeleton";
import orginationName from "../required";
import Backhost from "../required";
import Review_QNA from "./Review_QNA";
import ItemSlider from "./ItemSlider";
const Item = () => {
  const { slung } = useParams();
  const [Loading, setLoading] = useState(true);
  const [Sucess, setSucess] = useState(false);
  const [Quantity, setQuantity] = useState(1);
  const [Data, setData] = useState([]);
  const { authincated, token, TotalCartItems, setTotalCartItems } =
    useContext(authContext);
  const [ShowMessage, setShowMessage] = useState(false);
  const [CartProcessing, setCartProcessing] = useState(false);

  const [Message, setMessage] = useState("");

  const cartMessage = () => {
    setShowMessage(true);
  };

  const getData = async () => {
    let headersList = {
      Accept: "*/*",
      "auth-token": `${token}`,
    };

    let response = await fetch(
      `${Backhost.Backhost}/api/items/fulldetails/${slung}`,
      {
        method: "GET",
        headers: headersList,
      }
    );
    response = await response.json();
    if (response.sucess) {
      await setData(response);
      await setSucess(true);
      await setLoading(false);
    } else {
      await setSucess(false);
      await setLoading(false);
    }
  };


  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [slung]);

  //Add to cart button
  const AddToCart = () => {
    const handleAddToCart = async () => {
      await setMessage("");
      await setCartProcessing(true);
      await setShowMessage(false);
      let headersList = {
        Accept: `*/*`,
        "Content-Type": "application/json",
        "auth-token": `${token}`,
      };
      let request = await fetch(
        `${Backhost.Backhost}/api/auth/cart/${Data.item._id}`,
        {
          method: "POST",
          // body: `{"quantity": ${Quantity}}`,
          body: `{"quantity": ${Quantity}} `,
          headers: headersList,
        }
      );
      request = await request.json();
      setShowMessage(true);
      setMessage(request.message);
      setCartProcessing(false);
      if (request.sucess) {
        setTotalCartItems(TotalCartItems + 1);
      }
    };

    return (
      <div className="flex flex-col justify-end ml-3">
        <div className="flex items-center">
          <button
            onClick={authincated ? handleAddToCart : cartMessage}
            className={`${
              authincated ? "bg-indigo-500" : "bg-indigo-400 cursor-not-allowed"
            } outline-none text-white  font-semibold p-1 m-1 w-36 h-11 `}
          >
            <FontAwesomeIcon icon={faCartPlus} /> &nbsp; Add To cart
          </button>
          {CartProcessing ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <div className="w-4">&nbsp;</div>
          )}
        </div>
        {ShowMessage ? (
          <>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              <p
                className="shadow relative w-48 text-sm text-black"
                style={{ top: "-20px", backgroundColor: "#ffc069a3" }}
              >
                {!authincated ? "Login to add items in cart" : Message}
              </p>
            </motion.div>
          </>
        ) : (
          <div className="w-48">&nbsp;</div>
        )}
      </div>
    );
  };

  const handleQuantityChange = (e) => {
    if (parseInt(e.target.value) >= 1) {
      setQuantity(parseInt(e.target.value));
    } else {
      setQuantity(1);
    }
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function doNothing() {}

  return (
    <div>
      {Loading ? (
        <div
          className="text-center"
          style={{ marginTop: "10px", marginBottom: "60vh" }}
        >
          <ItemSkeleton />
        </div>
      ) : (
        <>
          {Sucess ? (
            <div className="flex flex-col ">
              <Helmet>
                <title>{Data.item.name}</title>
                <script type="application/ld+json">
                  {`{
                    "@context": "https://schema.org/", 
                    "@type": "Product", 
                    "name": "${Data.item.name}",
                    "image": "${Data.item.thumbnail}",
                    "offers": {
                      "@type": "Offer",
                      "url": "/item/${Data.item.slung}",
                      "priceCurrency": "NPR",
                      "price": "${Data.item.price}",
                      "availability": "https://schema.org/${
                        Data.item.InStock ? "InStock" : "OutOfStock"
                      }",
                      "itemCondition": "https://schema.org/NewCondition"
                    },
                    "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue": "${Data.review.averageReview}",
                      "bestRating": "5",
                      "worstRating": "1",
                      "ratingCount": "${Data.review.totalReview}"
                    }
                  }`}
                </script>
              </Helmet>
              <section className="text-gray-600 body-font overflow-hidden shadow-md">
                <div className="px-5 py-24 mx-auto">
                  <div className="lg:w-4/5 justify-center  flex flex-wrap">
                    <ImageMagnifier
                      width={"350px"}
                      src="https://fdn2.gsmarena.com/vv/pics/apple/apple-iphone-13-pro-max-01.jpg"
                    />

                    {/* <img
                    alt={Data.item.name}
                    className="lg:w-1/2 sticky w-full lg:h-auto h-64 object-cover object-center rounded"
                    src="https://dummyimage.com/400x400"
                  /> */}
                    <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                          Home
                        </Link>
                        <Link underline="hover" color="inherit" to="/item/">
                          Item
                        </Link>
                        <Typography color="text.primary">
                          {Data.item.name}
                        </Typography>
                      </Breadcrumbs>
                      <h2 className="text-sm title-font text-gray-500 tracking-widest">
                        {Data.item.category.toUpperCase()}
                      </h2>
                      <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                        {Data.item.name}
                      </h1>
                      <div className="flex mb-4">
                        <span className="flex">
                          <Rating
                            readOnly
                            value={
                              Data.review.averageReview === null
                                ? 5
                                : Data.review.averageReview
                            }
                            precision={0.1}
                          />{" "}
                          ({Data.review.totalReview})
                        </span>
                      </div>
                      <p className="leading-relaxed">
                        {Data.item.discription} Lorem ipsum dolor sit amet
                        consectetur adipisicing elit. Assumenda odio dignissimos
                        maiores ad nihil itaque odit. Aliquam laudantium
                        dolorum, incidunt accusamus magnam suscipit nisi sunt
                        explicabo temporibus nobis similique laboriosam deserunt
                        itaque nulla corrupti, optio soluta, distinctio eos
                        repudiandae minus? Quo, iure nam natus voluptatum
                        quaerat autem voluptas rerum recusandae! Distinctio
                        blanditiis ea, error, quos, provident officia assumenda
                        reprehenderit tempora aliquid vel temporibus? Alias
                        corporis quidem laborum doloremque error ullam odit
                        eligendi earum minus, atque ratione ad tempora delectus
                        similique quas! Odio asperiores, ipsa ipsam repudiandae
                        officiis inventore, veritatis commodi sequi est ex
                        necessitatibus provident. Ducimus explicabo ea soluta
                        nostrum?
                      </p>
                      <div className=" flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5">
                        Quantity: &nbsp;
                        <Tooltip title="Decrease">
                          <button
                            onClick={
                              Quantity > 1
                                ? () => {
                                    setQuantity(Quantity - 1);
                                  }
                                : doNothing()
                            }
                          >
                            <svg
                              className="fill-current text-gray-600 w-3 cursor-pointer"
                              viewBox="0 0 448 512"
                            >
                              <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                            </svg>
                          </button>
                        </Tooltip>
                        <input
                          className="mx-2 border text-center w-8"
                          type="text"
                          value={Quantity}
                          onChange={handleQuantityChange}
                        />
                        <Tooltip title="Increase">
                          <button
                            onClick={() => {
                              setQuantity(Quantity + 1);
                            }}
                          >
                            <svg
                              className="fill-current text-gray-600 w-3 cursor-pointer"
                              viewBox="0 0 448 512"
                            >
                              <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                            </svg>
                          </button>
                        </Tooltip>
                      </div>
                      <div className="flex justify-between align-middle">
                        <span className="title-font font-medium text-2xl text-gray-900">
                          {currencySymb.currencySymb}
                          {!Data.item.discount.status ? (
                            <span className="text-green-500">
                              {numberWithCommas(Data.item.price)}
                            </span>
                          ) : (
                            <>
                              <span className="text-gray-500 line-through">
                                {numberWithCommas(Data.item.price)}
                              </span>
                              {
                                <span className="text-green-500">
                                  &nbsp;
                                  {numberWithCommas(
                                    Data.item.price -
                                      (Data.item.price *
                                        Data.item.discount.pst) /
                                        100
                                  )}
                                </span>
                              }
                            </>
                          )}
                        </span>
                        <div className="flex justify-end">
                          <div>
                            <AddToCart />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Divider/>
                    <ItemSlider/>
                  </div>
                  {Data.review.totalReview != 0 && (
                    <div className="lg:ml-9 flex flex-wrap">
                      <div>
                        <div className="flex">
                          <span>
                            <Rating
                              size="small"
                              defaultValue={5}
                              max={5}
                              readOnly
                            />
                          </span>
                          <div className="w-48 block rounded-r-full text-right h-4  border bg-gray-200">
                            <span
                              className="bg-yellow-400 block rounded-r-full text-right text-xs h-4 "
                              style={{ width: Data.review.reviewStats.a }}
                            >
                              {Math.round(
                                Number(Data.review.reviewStats.a.slice(0, -1))
                              ) + "%"}
                            </span>
                          </div>
                        </div>
                        <div className="flex">
                          <span>
                            <Rating
                              size="small"
                              defaultValue={4}
                              max={5}
                              readOnly
                            />
                          </span>
                          <div className="w-48 block rounded-r-full text-right h-4  border bg-gray-200">
                            <span
                              className="bg-yellow-400 block rounded-r-full text-right text-xs h-4 "
                              style={{ width: Data.review.reviewStats.b }}
                            >
                              {Math.round(
                                Number(Data.review.reviewStats.b.slice(0, -1))
                              ) + "%"}
                            </span>
                          </div>
                        </div>
                        <div className="flex ">
                          <span>
                            <Rating
                              size="small"
                              defaultValue={3}
                              max={5}
                              readOnly
                            />
                          </span>
                          <div className="w-48 block rounded-r-full text-right h-4  border bg-gray-200">
                            <span
                              className="bg-yellow-400 block rounded-r-full text-right text-xs h-4 "
                              style={{ width: Data.review.reviewStats.c }}
                            >
                              {Math.round(
                                Number(Data.review.reviewStats.c.slice(0, -1))
                              ) + "%"}
                            </span>
                          </div>
                        </div>
                        <div className="flex ">
                          <span>
                            <Rating
                              size="small"
                              defaultValue={2}
                              max={5}
                              readOnly
                            />
                          </span>
                          <div className="w-48 block rounded-r-full text-right h-4  border bg-gray-200">
                            <span
                              className="bg-yellow-400 block rounded-r-full text-right text-xs h-4 "
                              style={{ width: Data.review.reviewStats.d }}
                            >
                              {Math.round(
                                Number(Data.review.reviewStats.d.slice(0, -1))
                              ) + "%"}
                            </span>
                          </div>
                        </div>
                        <div className="flex ">
                          <span>
                            <Rating
                              size="small"
                              defaultValue={1}
                              max={5}
                              readOnly
                            />
                          </span>
                          <div className="w-48 block rounded-r-full text-right h-4  border bg-gray-200">
                            <span
                              className="bg-yellow-400 block rounded-r-full text-right text-xs h-4 "
                              style={{ width: Data.review.reviewStats.e }}
                            >
                              {Math.round(
                                Number(Data.review.reviewStats.e.slice(0, -1))
                              ) + "%"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="lg:ml-6 w-64 lg:w-96">
                        <h3 className="font-bold text-lg">
                          How reviews are calculated?
                        </h3>
                        <p>
                          To calculate the overall star rating and percentage
                          breakdown by star, we don’t use a simple average.
                          Instead, our system considers things like how recent a
                          review is and if the reviewer bought the item on 
                           &nbsp;{orginationName.orginationName}. It also analyses reviews to verify
                          trustworthiness.
                        </p>
                      </div>
                    </div>
                  )}
                  <Review_QNA Data={Data} getData={getData} canReview={Data.review.canReview} />
                </div>
              </section>
            </div>
          ) : (
            <>
              <Error404 />
              {console.error("error 404 not found")}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Item;
