import Login from "./user/Login";
import { BrowserRouter as Router, Route } from "react-router-dom";
import SignUp from "./user/SignUp";
import ForgetPassword from "./user/ForgetPassword";
import ReqforgetPassword from "./user/ReqforgetPassword";
import Navigation from "./webComponents/navigation/Navigation";
import Home from "./home/home";
import CustomSwitch from "./components/CustomSwitch";
import Search from "./search/search";
import Footer from "./webComponents/footer/footer";
import Error404 from "./components/404"
import AuthState from "./context/auth/authState";
import Cart from "./cart/cart";
import Item from "./item/Item";
import User from "./user";
import Category from "./item/Category/Index";
function Routes() {
  return (
    <>
    <AuthState>
      <Router>
        <Navigation />
        <CustomSwitch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/login/">
            <Login />
          </Route>
          <Route exact path="/login/r=:path">
            <Login />
          </Route>
          <Route exact path="/signup">
            <SignUp />
          </Route>
          <Route exact path="/cart">
            <Cart />
          </Route>
          <Route exact path="/user/forgetpassword">
            <ReqforgetPassword />
          </Route>
          <Route exact path="/user/">
            <User />
          </Route>
          <Route exact path="/forgetpassword/:token">
            <ForgetPassword />
          </Route>
          <Route exact path="/item/:slung">
            <Item />
          </Route>
          <Route exact path="/search/q=:quary&c=:category">
            <Search />
          </Route>
          <Route exact path="/category/:category">
            <Category />
          </Route>
          <Route path="*">
            <Error404 />
          </Route>
        </CustomSwitch>
        <Footer />
      </Router>
      </AuthState>
    </>
  );
}

export default Routes;
