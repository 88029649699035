import React from "react"
import "./tag.css"
function Tag(props) {
  return (
    <svg
    viewBox="135.721 109.991 262.088 94.53"
    width={262.088}
    height={30}
    
    className="tag-svg-icon z-50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="100%" height="100%" fill="transparent"  />
    <rect
      vectorEffect="non-scaling-stroke"
      x={-250}
      y={-150}
      width={500}
      height={300}
      rx={66.843}
      ry={66.843}
      fill="none"
      transform="translate(250 150)"
    />
    <path
      vectorEffect="non-scaling-stroke"
      fill="#c51111"
      d="M192.042 114.256L136.818 158.4l54.851 43.398z"
    />
    <rect
      vectorEffect="non-scaling-stroke"
      x={-37.165}
      y={-37.165}
      rx={0}
      ry={0}
      width={74.33}
      height={74.33}
      fill="#c30000"
      transform="matrix(2.47 0 0 1.18 283.4 157.76)"
    />
    <circle
      vectorEffect="non-scaling-stroke"
      r={35}
      fill="#fff"
      transform="matrix(.21 0 0 .21 170.74 160.41)"
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M315.87 174.982V178.045q0 .832-.278 2.22-.278 1.387-1.388 3.052l-2.22 3.33q-1.11 1.665-2.497 3.053l-1.398 1.397M343.6 197.512h-1.121q-.555 0-1.388.555l-2.497 1.665q-1.665 1.11-3.33 2.775-1.665 1.665-2.498 2.775-.832 1.11-1.942 1.665-1.11.555-1.665 1.11l-.833.833q-.277.277-.555.277H328.05q1.11 0 3.052-.832 1.943-.833 3.33-1.388 1.388-.555 3.053-1.942 1.665-1.388 3.33-3.053l3.052-3.052q1.388-1.388 2.498-3.053 1.11-1.665 1.942-3.33.833-1.665 1.11-2.497.278-.832.833-1.387l.832-.833q.278-.277-.832.555-1.11.833-2.22 2.497-1.11 1.665-2.22 3.053-1.11 1.387-2.775 2.497-1.665 1.11-2.498 2.22l-1.665 2.22q-.832 1.11-1.387 2.22t-1.11 1.665l-.833.833q-.277.277-.277.555 0 .277-.555.277h-1.121"
      fill="none"
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M342.084 190.863V196.146q0 .278-.832.555-.833.278-1.11.833-.278.555-1.11 1.387-.833.833-1.388.833h-.565M338.195 195.929q.01 0 .288-.278.277-.277.555-.277h.288"
      fill="none"
    />
    <path
      vectorEffect="non-scaling-stroke"
      fill="#c30000"
      d="M393.924 127.36l-9.859 6.485-9.853 6.48v-7.963h-24.136v-10.445h24.136l-1.491-8.64 11.344 7.598z"
    />
    <path
      vectorEffect="non-scaling-stroke"
      fill="#c30000"
      d="M398.448 157.67l-15.413 10.19-10.187 10.184v-12.513h-24.956v-16.415h24.956v-11.82l10.187 10.184z"
    />
    <path
      vectorEffect="non-scaling-stroke"
      fill="#c30000"
      d="M394.334 188.01l-9.858 6.485-9.853 6.48v-7.963h-24.137v-10.445h24.137v-7.522l9.853 6.48z"
    />
    <g>
      <text
        fontFamily="Raleway"
        fontSize={94}
        fontWeight={900}
        style={{
          whiteSpace: "pre",
        }}
        fill="#fff"
        transform="matrix(.35 0 0 .45 271.16 162.39)"
      >
        <tspan x={-180.5} y={29.53}>
          {props.name.toUpperCase()}
        </tspan>
      </text>
    </g>
  </svg>
  )
}

export default Tag
