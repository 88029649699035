import { Skeleton } from "@mui/material";
import React from "react";

const ItemSkeleton = () => {
  return (
    <div>
      <section className="text-gray-600 body-font overflow-hidden shadow-md">
        <div className="px-5 mx-auto">
          <div className="lg:w-4/5 justify-center  flex flex-wrap">
            <Skeleton sx={{ height: "500px", width: "350px" }} />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6  lg:mt-0">
              <Skeleton sx={{ height: "30px", width: "150px" }} />
              <Skeleton sx={{ height: "30px", width: "80px" }} />
              <Skeleton sx={{ height: "80px", width: "200px" }} />
              <Skeleton sx={{ height: "400px", width: "100%" }} />

              <div className=" flex items-center pb-5 border-b-2 border-gray-100 mb-5">
                <Skeleton sx={{ height: "30px", width: "100px" }} />
              </div>
              <div className="flex justify-between align-middle">
                <span className="title-font font-medium text-2xl text-gray-900">
                  <Skeleton sx={{ height: "30px", width: "100px" }} />
                </span>
                <div className="flex justify-end">
                  <div>
                    <Skeleton sx={{ height: "80px", width: "150px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ItemSkeleton;
