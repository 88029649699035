import  React from 'react'

const Tag = (props) => {
    return (
    <svg
      viewBox="81.106 150.18 178.847 199.507"
      width={108.847}
      className="absolute"
      height={139.507}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <radialGradient
          gradientUnits="userSpaceOnUse"
          cx={140.172}
          cy={183.078}
          r={46.641}
          id="prefix__b"
          gradientTransform="translate(8.701 -11.809)"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#ccc" />
        </radialGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          x1={167.494}
          y1={156.395}
          x2={167.494}
          y2={213.574}
          id="prefix__a"
        >
          <stop offset={0} stopColor="red" />
          <stop offset={1} stopColor="#900" />
        </linearGradient>
      </defs>
      <ellipse
        cx={86.389}
        cy={188.402}
        rx={5.283}
        ry={38.222}
        fill="rgba(0,0,0,.62)"
      />
      <path
        stroke="red"
        fill="url(#prefix__a)"
        d="M86.078 157.638v55.935l90.74.001 72.093-57.179z"
      />
      <text
        style={{
          lineHeight: "34.1665px",
          whiteSpace: "pre",
        }}
        x={88.951}
        y={193.583}
        fill="url(#prefix__b)"
        fontFamily="Arial,sans-serif"
        fontSize={28}
        fontWeight={700}
      >
        {props.discount + "% OFF"}
      </text>
      <text
        style={{
          whiteSpace: "pre",
        }}
        x={253.884}
        y={345.332}
        fill="#333"
        fontFamily="Arial,sans-serif"
        fontSize={21.8}
      />
    </svg>
    )
}

export default Tag
