import { Rating } from "@mui/material";
import React from "react";
import Tag from "./tags/Tag_2";
import "./card.css";
import { Link } from "react-router-dom";
const Horizontal = (props) => {
  return (
    <div>
      <Link to={"/item/" + props.slung}>
        <div
          style={{ margin: "10px auto" }}
          className="sm:flex block items-center md:w-auto w-min shadow p-3 rounded md:flex-row flex-col md:mx-2"
        >
          <div
            className="bg-gray-100 sm:w-96 horizental-card-image "
            style={{ height: "min-content" }}
          >
            {props.discount === 0 ? "" : <Tag discount={props.discount} />}
            <img
              src={props.thumbnail}
              className="sm:w-full horizental-card-image"
              style={{ height: "min-content" }}
              alt="thumbnail"
            />
          </div>
          <div className="mx-2">
            <div>
              <h2 className="text-2xl font-semibold transform duration-200 hover:text-indigo-500">{props.title}</h2>
              <p className=" transform duration-200 hover:text-indigo-500">{props.discription.substring(0, 80) + "..."}</p>
            </div>
            <div>
              <div className="text-lg">
                <span className="text-red-500 text-base">रु. </span>
                {props.discount === 0 ? (
                  <span className="text-green-500">{props.price}</span>
                ) : (
                  <>
                    <span className="text-gray-500 line-through">
                      {props.price}
                    </span>
                    {
                      <span className="text-green-500">
                        &nbsp;
                        {props.price - (props.price * props.discount) / 100}
                      </span>
                    }
                  </>
                )}
              </div>
              <div className="flex">
                <Rating precision={0.1} value={props.rating} readOnly={true} />
                <span className="text-base">({props.totalReview})</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Horizontal;
