import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div>
      <Helmet>
        <title>404 Error -Page not found</title>
      </Helmet>
      <section className="flex items-center h-full p-16 dark:bg-coolGray-900 dark:text-coolGray-100">
        <div className="flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">
            <h2 className="mb-8 font-extrabold text-9xl dark:text-coolGray-600">
              <span className="sr-only">Error</span>404
            </h2>
            <p className="text-2xl font-semibold md:text-3xl">
              Sorry, we couldn't find this page.
            </p>
            <p className="mt-4 mb-8 dark:text-coolGray-400">
              But dont worry, you can find plenty of other things on our
              homepage.
            </p>
            <Link
              to="/"
              className="px-8 block py-3 bg-indigo-500 font-semibold rounded text-white hover:bg-indigo-600"
            >
             <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;  Back to homepage
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Error404;
