import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import loginImage from "../images/login.png";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import Sucess from "../components/alerts/Sucess";
import Loading from "../components/loading";
import Error from "../components/alerts/Error";
import authContext from "../context/auth/authContext";
import Backhost from "../required";
const Login = () => {
  const { settoken, authincated } = useContext(authContext);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [SucessST, setSucessST] = useState(false);
  const [errorST, seterrorST] = useState(false);
  const [LodingST, setLodingST] = useState(false);
  const [Message, setMessage] = useState("");
  const { path } = useParams();
  let history = useHistory();
  if (authincated) {
    history.push("/");
  }
  const loginForm = async (e) => {
    await setSucessST(false);
    await seterrorST(false);
    await setMessage("");
    e.preventDefault();
    if (credentials.email.indexOf("@") <= -1) {
      seterrorST(true);
      setSucessST(false);
      setMessage("Invalid Email Address");
    } else {
      setLodingST(true);
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let response = await fetch(`${Backhost.Backhost}/api/user/login`, {
        method: "POST",
        body: `{
          "email": "${credentials.email}",
          "password": "${credentials.password}"
      }`,
        headers: headersList,
      });
      response = await response.json();
      if (response.sucess) {
        await setSucessST(true);
        await seterrorST(false);
        await setMessage("Login sucessfully");
        // Save the auth token and redirect
        localStorage.setItem("token", response.authtoken);
        if (path !== undefined) {
          setTimeout(function () {
            history.push(path.replaceAll("%2F", "/"));
            settoken(response.authtoken);
          }, 500);
        } else {
          setTimeout(function () {
            history.push("/");
            settoken(response.authtoken);
          }, 500);
        }
      } else {
        setLodingST(false);
        setSucessST(false);
        seterrorST(true);
        setMessage(response.message);
      }
    }
  };
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="lg:flex">
        <div className="lg:w-1/2 xl:max-w-screen-sm">
          <div className="px-16 pt-4 h-7 mb-20">
            {SucessST ? <Sucess message={Message} /> : ""}
            {errorST ? <Error message={Message} /> : ""}
          </div>
          <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
            <h2
              className="text-center text-4xl text-indigo-900 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
            >
              Log in
            </h2>
            <div className="mt-12">
              <form onSubmit={loginForm}>
                <div>
                  <div className="text-sm font-bold text-gray-700 tracking-wide">
                    Email Address
                  </div>
                  <input
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={credentials.email}
                    onChange={onChange}
                    className=" w-full text-lg py-2 px-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="text"
                    placeholder="example@gmail.com"
                  />
                </div>
                <div className="mt-8">
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-bold text-gray-700 tracking-wide">
                      Password
                    </div>
                    <div>
                      <Link
                        to="/user/forgetpassword"
                        className="text-xs font-display font-semibold text-indigo-600 hover:text-indigo-800
                                        cursor-pointer"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <input
                    value={credentials.password}
                    onChange={onChange}
                    autoComplete="password"
                    name="password"
                    id="password"
                    className="w-full text-lg py-2 px-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="password"
                    placeholder="Enter your current password"
                  />
                </div>
                <div className="mt-10">
                  <button
                    className="bg-indigo-500 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
                  >
                    <div className="flex content-center justify-center">
                      {LodingST ? (
                        <Loading />
                      ) : (
                        <span style={{ padding: "3.5px" }}>Login In</span>
                      )}
                    </div>
                  </button>
                </div>
              </form>
              <div className="mt-4 text-sm font-display font-semibold text-gray-700 text-center">
                Don't have an account ?{" "}
                <Link
                  to="/signup"
                  className="cursor-pointer text-indigo-600 hover:text-indigo-800"
                >
                  Sign up
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen">
          <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
            <img src={loginImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
