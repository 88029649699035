import { Divider, Skeleton } from "@mui/material";
import React from "react";

const ReviewSkeleton = () => {
  return (
    <div>
      <div className=" bg-white p-2 pt-4 rounded ">
        <div className="flex flex-row justify-between ml-3">
          <div className="flex">
            <div className="mr-3">
              <Skeleton
                animation="wave"
                variant="circular"
                width={40}
                height={40}
              />
            </div>
            <div>
              <div className="font-semibold">
                <Skeleton sx={{ height: "20px", width: "120px" }} />
              </div>
              <div className="text-xs text-gray-500 ">
                <Skeleton sx={{ height: "20px", width: "80px" }} />
              </div>
            </div>
          </div>
          <div>
            <Skeleton sx={{ height: "20px", width: "60px" }} />
          </div>
        </div>
        <div className="mt-1 px-2 pr-4 w-full">
          <Skeleton sx={{ height: "60px", width: "80%" }} />
        </div>
      </div>
      <Divider/>
    </div>
  );
};

export default ReviewSkeleton;
