import React, { useState, useEffect } from "react";
import {
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import AddNew from "./AddNew";
import Backhost from "../../required";
import { Alert, CircularProgress, Skeleton, Snackbar } from "@mui/material";
import Edit from "./Edit";
const BillingAddress = (props) => {
  const { token } = props;

  const [Addresses, setAddresses] = useState([]);
  const [Sucess, setSucess] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [DeleteSucess, setDeleteSucess] = useState(false);
  const [EditMode, setEditMode] = useState(false);
  const [EditID, setEditID] = useState("");

  const getAddresses = async () => {
    await setSucess(false);
    await setLoading(true);
    let headersList = {
      Accept: "*/*",
      "auth-token": `${token}`,
    };

    let reqOptions = {
      url: `${Backhost.Backhost}/api/auth/user/setting/address`,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    await setAddresses(response.data.addresses);
    await setSucess(response.data.sucess);
    await setLoading(false);
  };

  const DeleteAddress = async (id) => {
    let headersList = {
      Accept: "*/*",
      "auth-token": `${token}`,
    };

    let reqOptions = {
      url: `${Backhost.Backhost}/api/auth/user/setting/address/${id}`,
      method: "DELETE",
      headers: headersList,
    };

    const response = await axios.request(reqOptions);
    if (response.data.sucess) {
      setDeleteSucess(true);
      getAddresses();
    }
  };

  useEffect(() => {
    getAddresses();
     // eslint-disable-next-line 
  }, []);

  return (
    <div className="overflow-hidden">
      <motion.div
        initial={{ translateX: 1000, scale: 0.8 }}
        animate={{ translateX: 0, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 30,
        }}
      >
        <div className="m-2">
          <div className="flex flex-col w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Billing/dilivery Address
            </h1>
            <p className="lg:w-2/3 leading-relaxed text-base">
              You can change your Billing address and dilivery address from here
            </p>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="lg:w-1/3 m-2 p-2 ">
            <div>
              <h1 className="text-2xl font-medium title-font mb-4 text-gray-900">
                Saved Address:
              </h1>
              <div>
                {/* <p className="lg:w-2/3 leading-relaxed text-base">
            No saved addresses
          </p>       */}
                {Loading ? (
                  <div>
                    <div>
                    <Skeleton height={100}  />
                    <div className="flex justify-between">
                    <Skeleton height={40} width={100}  />
                    <Skeleton height={40} width={100}  />
                    </div>
                    </div>
                  </div>
                ) : Sucess ? (
                  <>
                    {Addresses.map((data) => {
                      return (
                        <>
                          <div className=" m-2 shadow-md p-2">
                            <p className="shadow-sm">
                              {data.fullname}, {data.phonenumber},
                              {data.province}, {data.city}, {data.extra}
                            </p>

                            <div className="flex justify-between">
                              <button
                                onClick={() => {
                                  DeleteAddress(data._id);
                                }}
                                className="text-red-500"
                              >
                                <FontAwesomeIcon icon={faTrashAlt} /> Remove
                              </button>
                              &nbsp;
                              <button
                                onClick={async () => {
                                  await setEditID(data._id);
                                  await setEditMode(false);
                                  await setEditMode(true);
                                }}
                                className="text-indigo-500"
                              >
                                <FontAwesomeIcon icon={faEdit} />
                                Edit
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <p>No addresses found</p>
                )}
              </div>
            </div>
          </div>
          <Snackbar
            open={DeleteSucess}
            autoHideDuration={5000}
            onClose={() => {
              setDeleteSucess(false);
            }}
          >
            <Alert
              onClose={() => {
                setDeleteSucess(false);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              Address Deleted sucessfully
            </Alert>
          </Snackbar>
          <div className="px-5 ">
            {EditMode ? (
              <Edit
                id={EditID}
                token={token}
                getAddresses={getAddresses}
                setEditMode={setEditMode}
              />
            ) : (
              <AddNew token={token} getAddresses={getAddresses} />
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default BillingAddress;
