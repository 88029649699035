import React, { useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Carousel from "react-multi-carousel";
import ItemCard from "../components/cards/itemsCard";
import CardSkeletion from "../home/Skeleton";
import Backhost from "../required";

const ItemSlider = () => {
  const getItems = async () => {
    const response = await fetch(Backhost.Backhost + "/api/items/discounted");
    const data = await response.json();
    if (data.sucess) {
      await setItemsSucess(true);
      await setItems(data.items);
    } else {
      const response = await fetch(Backhost.Backhost + "/api/items/latest");
      const data = await response.json();
      await setItemsSucess(true);
      await setItems(data.items);
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1294 },
      items: 4,
      slidesToSlide: 4,
    },
    laptop: {
      breakpoint: { max: 1294, min: 1000 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1000, min: 568 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 568, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const [Items, setItems] = useState([]);
  const [ItemsSucess, setItemsSucess] = useState(false);
  return (
    <LazyLoadComponent beforeLoad={getItems}>
      {ItemsSucess ? (
        <>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            transitionDuration={1000}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
          >
            {Items.map((itemsData) => {
              if (itemsData.length !== 0) {
                return (
                  <div key={itemsData._id}>
                    <ItemCard
                      slung={itemsData.slung}
                      thumbnail={itemsData.thumbnail}
                      itemID={itemsData._id}
                      title={itemsData.name}
                      discountStatus={itemsData.discount.status}
                      rating={itemsData.review.average}
                      totalReview={itemsData.review.total}
                      discription={itemsData.discription}
                      price={itemsData.price}
                      discount={itemsData.discount.pst}
                      tagname={itemsData.discount.pst + "% Off"}
                    />
                  </div>
                );
              } else {
                return (
                  <p className="font-bold text-2xl">
                    No items is In offer right now
                  </p>
                );
              }
            })}
          </Carousel>
        </>
      ) : (
        <>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            transitionDuration={1000}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
          >
            <div>
              <CardSkeletion />
            </div>
            <div>
              <CardSkeletion />
            </div>
            <div>
              <CardSkeletion />
            </div>
            <div>
              <CardSkeletion />
            </div>
          </Carousel>
        </>
      )}
    </LazyLoadComponent>
  );
};

export default ItemSlider;
