import React from 'react'
import AskQuestion from "./AskQuestion";
import PostReview from "./PostReview";
import {

    Tabs,
    Tab,
  } from "@mui/material";
const Review_QNA = (props) => {
  const {getData} = props;
    const [Tabvalue, setTabValue] = React.useState(0);
    const handleTabChange = async (event, newValue) => {
      await setTabValue(newValue);
    };
    return (
      <div className="lg:ml-10 ml-4">
        <Tabs value={Tabvalue} onChange={handleTabChange}>
          <Tab index={0} label="Reviews" />
          <Tab index={1} label="Q&A " />
        </Tabs>
        <div>
          {Tabvalue === 1 ? (
            <AskQuestion id={props.Data.item._id} />
          ) : Tabvalue === 0 ? (
            <PostReview id={props.Data.item._id} getData={getData} canReview={props.canReview} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
}

export default Review_QNA