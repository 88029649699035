import React, { useState, useContext } from "react";
import { Link, Route, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import {
  Alert,
  Menu,
  MenuItem,
  Skeleton,
  Snackbar,
  Tooltip,
} from "@mui/material";
import {
  faBars,
  faSearch,
  faTimes,
  faSignOutAlt,
  faShoppingCart,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import authContext from "../../context/auth/authContext";
import "./navigation.css";
import { Avatar } from "@mui/material";
import Notification from "./Notification";
import Category from "./Category";
const Navigation = () => {
  let history = useHistory();
  const location = useLocation();
  const {
    UserDetails,
    authincated,
    settoken,
    tokenLoading,
    AuthResponse,
    TotalCartItems,
    token,
  } = useContext(authContext);
  const [UserOptions, setUserOptions] = useState(false);
  const [AlertOpen, setAlertOpen] = useState(true);
  const [search, setsearch] = useState({ tearm: "", category: "all" });
  const onChange = (e) => {
    setsearch({ ...search, [e.target.name]: e.target.value });
  };

  // function to get user details from backend

  //  function to  handle the search
  const handleSearch = (e) => {
    e.preventDefault();
    let searchTearm = search.tearm
      .replaceAll(/\//g, "%2F")
      .replaceAll(/\\/g, "%2F");
    history.push(`/search/q=${searchTearm}&c=${search.category}`);
  };

  // to toggle class of sidebar
  const handleSidebar = () => {
    document
      .getElementById("mobile-sidebar")
      .classList.toggle("sidebarPassive");
  };

  const AlertMessage = () => {
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlertOpen(false);
    };

    if (tokenLoading && !AuthResponse.sucess && localStorage.getItem("token")) {
      return (
        <>
          {!tokenLoading && (
            <Snackbar
              open={AlertOpen}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {AuthResponse.message}
              </Alert>
            </Snackbar>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  const LoginUser = () => {
    //function to handle userOptions dropdown
    const toggleUserOption = () => {
      if (UserOptions) {
        setUserOptions(false);
      } else if (!UserOptions) {
        setUserOptions(true);
      }
      // to handle click outside element
    };
    const handleLogout = (e) => {
      settoken("");
      localStorage.removeItem("token");
    };

    const UserAvatar = () => {
      function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
      }

      function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(" ")[0][0].toUpperCase()}${name
            .split(" ")[1][0]
            .toUpperCase()}`,
        };
      }
      return (
        <>
          {UserDetails.sucess ? (
            <Tooltip title={"User"}>
              <Avatar {...stringAvatar(UserDetails.name)} />
            </Tooltip>
          ) : (
            <Avatar sx={{ bgcolor: "#6366f1" }} />
          )}
        </>
      );
    };
    return (
      <>
        {tokenLoading ? (
          <div className="flex">
            {" "}
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
            &nbsp;
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
            &nbsp;
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          </div>
        ) : (
          <>
            {authincated ? (
              <div className="flex">
                <Notification token={token} />

                <Link to="/cart">
                  <div className="mr-2">
                    <Tooltip title="Cart">
                      <IconButton aria-label="cart">
                        <Badge badgeContent={TotalCartItems} color="primary">
                          <FontAwesomeIcon icon={faShoppingCart} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Link>
                <div onClick={toggleUserOption} className="cursor-pointer">
                  <UserAvatar />

                  {UserOptions && (
                    <Menu
                      open={UserOptions}
                      onClose={() => {
                        setUserOptions(false);
                      }}
                      onClick={() => {
                        setUserOptions(false);
                      }}
                      style={{ top: "35px", zIndex: "99999" }}
                      className="top-56"
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            zIndex: 99999,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 99999,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                      <Link to="/user">
                        <MenuItem>
                          <FontAwesomeIcon icon={faCog} />
                          Settings
                        </MenuItem>
                      </Link>
                      <MenuItem onClick={handleLogout}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        Logout
                      </MenuItem>
                    </Menu>
                  )}
                </div>
              </div>
            ) : (
              <>
                <Link
                  onClick={window.screen.width < 640 && handleSidebar}
                  to={`/login/r=${location.pathname.replaceAll("/", "%2F")}`}
                  className="py-2 px-3 my-1 font-medium text-gray-500 rounded-3xl hover:bg-indigo-500 hover:text-white transition duration-300"
                >
                  Log In
                </Link>
                <Link
                  onClick={window.screen.width < 640 && handleSidebar}
                  to="/signup"
                  className="py-2 px-3 my-1 font-medium text-white bg-indigo-500 rounded-3xl hover:bg-indigo-500 transition duration-300"
                >
                  Sign Up
                </Link>
              </>
            )}
          </>
        )}
      </>
    );
  };

  if (window.screen.width > 1100) {
    return (
      <Route>
        <div className="sticky top-0" style={{ zIndex: "9998" }}>
          <nav className="bg-white shadow-lg">
            <div className=" mx-auto px-4">
              <div className="flex justify-between">
                <div className="flex space-x-2">
                  <div>
                    {/* Website Logo  */}
                    <Tooltip title="Home">
                      <Link to="/" className="flex items-center py-4 ">
                        <img
                          src="/logo192.png"
                          alt="Logo"
                          className="h-8 w-8 mr-2"
                        />
                      </Link>
                    </Tooltip>
                  </div>
                  {/* Primary Navbar items  */}
                  <div className="flex items-center w-max">
                    <Link
                      to="/"
                      className="py-4 px-2 text-gray-500 hover:text-indigo-500 font-semibold "
                    >
                      Home
                    </Link>

                    <Link
                      to="/about"
                      className="py-4 px-2 text-gray-500 font-semibold hover:text-indigo-500 transition duration-300"
                    >
                      About
                    </Link>
                    <Link
                      to="/contact"
                      className="py-4 px-2 text-gray-500 font-semibold hover:text-indigo-500 transition duration-300"
                    >
                      Contact Us
                    </Link>
                    <form onSubmit={handleSearch} className="flex">
                      <div className="flex border-2 px-0 py-0 border-indigo-500 rounded focus:border-indigo-700 border-r-0">
                        <div>
                          <label
                            className="h-6 outline-none py-2"
                            htmlFor="category"
                          ></label>

                          <select
                            name="category"
                            onChange={onChange}
                            value={search.category}
                            className="outline-none"
                            style={{ height: "35px" }}
                            id="category"
                          >
                            <option value={"all"}>All</option>
                            <Category />
                          </select>
                        </div>
                        <div>
                          <input
                            name="tearm"
                            onChange={onChange}
                            value={search.tearm}
                            className="py-1 px-2 text-gray-500 font-semibold border-r-2 border-l-2 border-indigo-500  focus:outline-none"
                            style={{ width: "500px", height: "35px" }}
                            type="search"
                            required
                          />
                        </div>
                      </div>
                      <Tooltip title="Search">
                        <button className=" text-white rounded-full mx-1 p-1 border-indigo-500 border-2 font-semibold cursor-pointer px-2 bg-indigo-500 ">
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                      </Tooltip>
                    </form>
                  </div>
                </div>
                {/* Secondary Navbar items  */}
                <div className="flex items-center space-x-3 ">
                  <LoginUser />
                </div>
              </div>
            </div>
          </nav>
        </div>
        <AlertMessage />
      </Route>
    );
    // for mobile device
  } else {
    return (
      <Route>
        <div className="sticky top-0" style={{ zIndex: "9998" }}>
          <nav className="bg-white shadow-lg p-0">
            <div className=" mx-auto px-1">
              <div className="flex justify-between items-center ">
                <div className="flex sm:space-x-2 justify-center">
                  <div>
                    {/* Website Logo  */}
                    <Link to="/" className="flex items-center py-4 ">
                      <img
                        src="/logo192.png"
                        alt="Logo"
                        className="h-8 w-8 mr-2"
                      />
                    </Link>
                  </div>
                  {/* Primary Navbar items  */}
                  <div className="flex items-center w-max">
                    <Link
                      to="/"
                      className="py-4 px-2 text-gray-500 hover:text-indigo-500 font-semibold "
                    >
                      Home
                    </Link>

                    <Link
                      to="/about"
                      className="py-4 px-2 text-gray-500 font-semibold hover:text-indigo-500 transition duration-300"
                    >
                      About
                    </Link>
                    <Link
                      to="/contact"
                      className="py-4 px-2 text-gray-500 font-semibold hover:text-indigo-500 transition duration-300"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
                {/* Secondary Navbar items  */}
                <div className="sm:flex items-center space-x-3 ">
                  {authincated && <LoginUser />}
                </div>
              </div>
              <form onSubmit={handleSearch} className="flex justify-center">
                <div
                  onClick={handleSidebar}
                  className="navigator-bars fixed top-28 shadow left-3 text-2xl px-2 py-1 rounded-full"
                >
                  <FontAwesomeIcon icon={faBars} />
                </div>
                <div className="flex border-2 px-0 py-0 border-indigo-500 rounded focus:border-indigo-700 border-r-0">
                  <div>
                    <label
                      className="h-6 outline-none py-2"
                      htmlFor="category"
                    ></label>

                    <select
                      name="category"
                      onChange={onChange}
                      value={search.category}
                      className="outline-none"
                      style={{ height: "35px" }}
                      id="category"
                    >
                      <option value={"All"}>All</option>
                      <Category />
                    </select>
                  </div>
                  <div>
                    <Tooltip title="Type here to search...">
                      <input
                        name="tearm"
                        onChange={onChange}
                        value={search.tearm}
                        placeholder="Type here to search..."
                        className="py-1 px-2 sm:w-96 text-gray-500 font-semibold border-r-2 border-l-2 border-indigo-500  focus:outline-none"
                        style={{
                          maxWidth: "500px",
                          height: "35px",
                          width: "--webkit-fill-avalable",
                        }}
                        type="search"
                        required
                      />
                    </Tooltip>
                  </div>
                </div>
                <button className=" text-white rounded-full mx-1 p-1 border-indigo-500 border-2 font-semibold cursor-pointer px-2 bg-indigo-500 ">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </form>
            </div>
            {/* sidebar for mobile device */}
            <div
              id="mobile-sidebar"
              className="fixed z-50 sm:hidden top-0 flex w-screen flex-row sidebarPassive sidebarActive sidebar justify-between "
            >
              <div
                onClick={handleSidebar}
                className="navigator-bars absolute top-1 left-36 text-xl"
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div className="bg-white h-screen pt-4 w-80 flex justify-center">
                <div className="flex flex-col justify-self-center w-24">
                  {!authincated && <LoginUser />}
                </div>
              </div>
              <div
                onClick={handleSidebar}
                style={{
                  width: "-webkit-fill-available",
                  backdropFilter: "blur(4px)",
                }}
                className="h-screen w-max"
              ></div>
            </div>
          </nav>
        </div>
        <AlertMessage />
      </Route>
    );
  }
};

export default Navigation;
