import { Skeleton } from "@mui/material";
import React from "react";

const CardSkeletion = () => {
  return (
    <div className="w-72 h-96 m-2 shadow hover:shadow-2xl duration-500 rounded-xl">
      <Skeleton style={{transform: "none"}} variant="text" className="w-full scale-0 transform-none" sx={{ height: "180px" }} />

      <div className="mx-2 mt-5">
        <Skeleton variant="text"  sx={{ height: "40px", width: "200px" }} />

        <Skeleton variant="text" sx={{ height: "40px", width: "200px" }} />
        <Skeleton variant="text" className="rounded-3xl" sx={{ height: "60px", width: "120px" }} />
      </div>
    </div>
  );
};

export default CardSkeletion;
