import React, { useState } from "react";
import "./forgetPassword.css";
import { useParams } from "react-router-dom";
import Sucess from "../components/alerts/Sucess";
import Error from "../components/alerts/Error";
import { useHistory } from "react-router-dom";
import Backhost from "../required"
const ForgetPassword = () => {
 
  let history = useHistory();
  const [email, setemail] = useState("");
  const [SucessST, setSucessST] = useState(false);
  const [errorST, seterrorST] = useState(false);
  const [Message, setMessage] = useState("");
  const [credentials, setCredentials] = useState({
    password: "",
    checkPassword: "",
  });
  const { token } = useParams();
  const [Load, setLoad] = useState(true);
  async function check() {
    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let response = await fetch(Backhost.Backhost +"/forgetpassword/verify", {
      method: "POST",
      body: `{"token": "${token}"}`,
      headers: headersList,
    });
    response = await response.json();
    setLoad(response.sucess);
    setemail(response.email);
  }
  check();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setSucessST(false);
    await seterrorST(false);
    await setMessage("");
    if (credentials.password === "" && credentials.checkPassword === "") {
      setSucessST(false);
      seterrorST(true);
      setMessage("Password is required");
    } else if (credentials.password !== credentials.checkPassword) {
      setSucessST(false);
      seterrorST(true);
      setMessage("Password doesn't match");
    } else if (credentials.password.length < 6) {
      setSucessST(false);
      seterrorST(true);
      setMessage("Password must be 6 charecter long");
    } else {
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let PResponse = await fetch("http://localhost:3001/forgetpassword", {
        method: "PATCH",
        body: `{"token": "${token}",
              "password": "${credentials.password}"    
      }`,
        headers: headersList,
      });
      PResponse = await PResponse.json();
      console.log(PResponse);
      if (PResponse.sucess) {
        setSucessST(true);
        seterrorST(false);
        setMessage(PResponse.message);
        setTimeout(function () {
          history.push("/login");
        }, 500);
      } else {
        setSucessST(false);
        seterrorST(true);
        setMessage("Unknown error");
      }
    }
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  return (
    <>
      {Load ? (
        <form onSubmit={handleSubmit}>
          <div className="px-16 pt-4 h-7 mb-20">
            {SucessST ? <Sucess message={Message} /> : ""}
            {errorST ? <Error message={Message} /> : ""}
          </div>
          <div>
            <div class="container">
              <div class="intro-text">
                <h2>Forget Password for Email</h2>
                <small>{email}</small>
              </div>
              <div class="inputs">
                <div class="input">
                  <input
                    onChange={onChange}
                    type="password"
                    name="password"
                    id="password"
                    required
                  />
                  <label htmlFor="password">Password</label>
                </div>
                <div class="input">
                  <input
                    onChange={onChange}
                    type="password"
                    name="checkPassword"
                    id="passwordA"
                    required
                  />
                  <label htmlFor="passwordA">Enter Password Again</label>
                </div>
              </div>
              <button className="forget-button">Change Password</button>
            </div>
          </div>
        </form>
      ) : (
        <div
          style={{
            display: "block",
            margin: "20vh auto",
            fontFamily:
              "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
          }}
        >
          <img
            style={{ width: "250px", display: "block", margin: "0 auto" }}
            src="http://localhost:3001/static/failed.png"
            alt="failed"
          />
          <h2 style={{ display: "block", textAlign: "center", color: "red" }}>
            Failed to open
          </h2>
          <p style={{ display: "block", textAlign: "center" }}>
            URL is expired or not available
          </p>
        </div>
      )}
    </>
  );
};

export default ForgetPassword;
