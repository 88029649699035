import React, { useEffect, useState } from "react";
import { Switch, useLocation } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState("");
  const location = useLocation();
  TopBarProgress.config({
    barColors: {
      "0.1": "#6366f1",
      "1.0": "#0061ff",
    },
    shadowBlur: 5,
  });
  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc(""); 
    }
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [location]);
  return (
    <>
      {progress && <TopBarProgress className="top-loding-bar" />}
      <Switch>{children}</Switch>
   {  useEffect(() => {
    setProgress(false);
  }, [prevLoc])}
    </>
    
  );
};

export default CustomSwitch;
