import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import orginationName from "../../required";
import { Link, useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname.indexOf("/forgetpassword") >= 0 ||
      location.pathname === "/cart" ||
      location.pathname.indexOf("/user") >= 0 ? (
        ""
      ) : (
        <div>
          <svg
            className="absolute overflow-hidden"
            style={{ zIndex: "-1" }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#6366f1"
              fillOpacity="0.8"
              d="M0,32L40,37.3C80,43,160,53,240,80C320,107,400,149,480,170.7C560,192,640,192,720,170.7C800,149,880,107,960,117.3C1040,128,1120,192,1200,197.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
            ></path>
          </svg>
          <footer className="footer sm:pt-16 text-gray-800">
            <div className="px-6 mx-auto space-y-6 divide-y divide-gray-400 md:space-y-12 divide-opacity-50">
              <div className="grid grid-cols-12">
                <div className="pb-6 col-span-full md:pb-0 md:col-span-6">
                  <div className="flex justify-center space-x-3 md:justify-start">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full">
                      <Link to="/" className="flex items-center py-4 ">
                        <img
                          src="/logo192.png"
                          alt="Logo"
                          className="h-8 w-8 mr-2"
                        />
                      </Link>
                    </div>
                    <div>
                      <span className="self-center text-2xl font-semibold">
                        {orginationName.orginationName}
                      </span>
                      <p>example@example.com</p>
                      <p>+977 9876543210</p>
                    </div>
                  </div>
                </div>
                <div className="col-span-6 text-center md:text-left md:col-span-3">
                  <p className="pb-1 text-lg font-medium">Category</p>
                  <ul>
                    <li>
                      <Link
                        to="/"
                        className="hover:dark:text-violet-400 hover:text-indigo-700"
                      >
                        Link
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/"
                        className="hover:dark:text-violet-400 hover:text-indigo-700"
                      >
                        Link
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-span-6 text-center md:text-left md:col-span-3">
                  <p className="pb-1 text-lg font-medium">Category</p>
                  <ul>
                    <li>
                      <Link
                        to="/"
                        className="hover:dark:text-violet-400 hover:text-indigo-700"
                      >
                        Link
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/"
                        className="hover:dark:text-violet-400 hover:text-indigo-700"
                      >
                        Link
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid justify-center pt-6 lg:justify-between">
                <div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
                  <span>
                    Copyright © {new Date().getFullYear()}{" "}
                    {orginationName.orginationName} All rights reserved
                  </span>
                  <Link to="/" className="hover:text-indigo-700">
                    <span>Privacy policy</span>
                  </Link>
                  <Link to="/" className="hover:text-indigo-700">
                    <span>Terms of service</span>
                  </Link>
                </div>
                <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://facebook.com/"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://instagram.com/"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};
export default Footer;
